<template>
  <div class="container mt-3">
    <h1>Справочники</h1>
    <DxDataGrid
        :data-source="list"
        :show-row-lines="true"
        :show-borders="true"
        :expanded-row-keys="expandedRowKeys"
        :column-auto-width="true"
        key-expr="id"
        parent-id-expr="parentId"
        @row-inserting="onInserting"
    >
      <DxEditing
          :allow-updating="false"
          :allow-deleting="false"
          :allow-adding="true"
          mode="row"
      />

      <DxRowDragging
          :allow-drop-inside-item="allowDropInsideItem"
          :allow-reordering="allowReordering"
          :show-drag-icons="showDragIcons"
      />
      <DxColumn data-field="name" caption="Название" />
      <DxColumn
          :width="125"
          data-field="type"
          caption="Тип"
      >
        <DxLookup
            :data-source="types"
            display-expr="title"
            value-expr="name"
        />
      </DxColumn>
      <DxColumn type="buttons">
<!--        <DxButton name="add" />-->
<!--                <DxButton name="edit" />-->
                <DxButton name="save" />
                <DxButton name="cancel" />
        <!--        $router.push({name: 'ProductType', params: {id: }})-->
        <DxButton
            text="Значения"
            :on-click="toItemList"
        />
        <DxButton name="delete" />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxColumn,
  DxRowDragging,
  DxDataGrid,
  DxButton,
  DxEditing,
  DxLookup,
} from "devextreme-vue/data-grid";
import api from "@/modules/Guide/api";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxRowDragging,
    DxButton,
    DxEditing,
    DxLookup,
  },
  data() {
    return {
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      list: [],
      types: {},
    };
  },
  async mounted() {
    await this.loadTypes();
    await this.loadList();
  },
  methods: {
    toItemList(event) {
      this.$router.push({
        name: "GuideItemList",
        params: { id: event.row.data.id },
      });
    },
    async onInserting({ data }) {
      console.log(data)
      // await api.insert(data);
      return false;
    },
    async create() {
      // await api.create("test");
      return false;
    },
    async loadList() {
      const { data } = await api.getGuideList();
      this.list = data;
    },
    async loadTypes() {
      const { data } = await api.getGuideTypes();
      this.types = data;
    },
  },
};
</script>

<!--<style>-->
<!--#employees {-->
<!--  max-height: 600px;-->
<!--}-->
<!--</style>-->
