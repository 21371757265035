<template>
  <div class="container mt-3">
    <h1>Прайслисты</h1>
    <div class="d-flex">
      <div>
        <label for="formFile">Импорт</label>
        <input id="formFile"
               ref="importCsvFile"
               class="form-control"
               name="importCsvFile"
               placeholder="Импорт"
               type="file"
               @change="importCsv">
      </div>
      <div class="ms-3">
        <label>Экспорт</label>
        <div>
          <a href="#" class="btn btn-outline-success" @click.prevent="exportPriceListsCsvByCityId">Скачать CSV</a>
        </div>
      </div>
    </div>
    <DxDataGrid
      :data-source="list"
      :remote-operations="false"
      :allow-column-reordering="true"
      :row-alternation-enabled="true"
      :show-borders="true"
      :column-auto-width="true"
      :expanded-row-keys="expandedRowKeys"
      key-expr="id"
      @row-inserting="onInserting"
      @row-updating="onUpdating"
    >
      <DxEditing
        :allow-updating="true"
        :allow-deleting="false"
        :allow-adding="true"
        mode="row"
      />

      <DxColumn data-field="name" caption="Название" />

      <DxColumn type="buttons">
        <DxButton name="edit" />
        <DxButton text="Цены" :on-click="toPrices" />
        <DxButton name="delete" />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxButton,
  DxEditing,
} from "devextreme-vue/data-grid";
import api from "@/modules/Prices/api";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxEditing,
  },
  data() {
    return {
      list: [],
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        if (target === "search" && typeof filterValue === "string") {
          return [this.dataField, "contains", filterValue];
        }
        return function (data) {
          return (data.catalogElementIds || []).indexOf(filterValue) !== -1;
        };
      },
    };
  },
  async mounted() {
    await this.loadItems();
  },
  methods: {
    async importCsv(event) {
      await api.importPriceListsCsv(event.target.files[0]);
      await this.loadItems();
      this.$refs.importCsvFile.value = null
    },
    async exportPriceListsCsvByCityId() {
      const response = await api.exportPriceListsCsv(this.$route.params.id);
      const type = response.headers['content-type']
      const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'import_price_lists.csv'
      link.click()
    },
    onValueChanged(value, cellInfo) {
      cellInfo.setValue(value);
      cellInfo.component.updateDimensions();
    },
    cellTemplate(container, options) {
      const noBreakSpace = "\u00A0";
      const text = (options.value || [])
        .map((element) => options.column.lookup.calculateCellValue(element))
        .join(", ");
      container.textContent = text || noBreakSpace;
      container.title = text;
    },
    toPrices(event) {
      this.$router.push({
        name: "PriceListPrices",
        params: {
          priceListGroupId: this.$route.params.id,
          priceListId: event.row.data.id,
        },
      });
    },
    async onInserting({ data }) {
      await api.insertPriceList(data);
      await this.loadItems();
    },
    async onUpdating(data) {
      await api.updatePriceList(data.oldData.id, data.newData);
    },
    async loadItems() {
      const { data } = await api.loadPriceLists();
      this.list = data;
    },
  },
};
</script>
