import axios from "axios";

const url = "/admin/catalog/property/value";

export default {
    async addPropertyGuideItemValue(
        propertyId,
        itemId,
        priceNameId
    ) {
        return await axios.post(`${url}/${propertyId}`, {
            itemId,
            priceNameId
        });
    },
    async insertValuePriceModifier(
        valueId,
        currentValuePriceModifierTargetValueId,
        currentValuePriceModifierPriceNameId
    ) {
        return await axios.post(`${url}/${valueId}/value-price-modifier`, {
            currentValuePriceModifierTargetValueId,
            currentValuePriceModifierPriceNameId
        });
    },
    async addAllowedWithValues(
        valueId,
        allowedWithValueIds
    ) {
        return await axios.put(`${url}/${valueId}/add-allowed-with`, {
            allowedWithValueIds,
        });
    },
    async removeAllowedWithValues(
        valueId,
        allowedWithValueId
    ) {
        return await axios.put(`${url}/${valueId}/remove-allowed-with`, {
            allowedWithValueId,
        });
    },
    async addPropertyExcludeProperties(
        propertyId,
        excludePropertyIds
    ) {
        return await axios.put(`${url}/property/${propertyId}/add-exclude-properties`, {
            excludePropertyIds,
        });
    },
    async removePropertyExcludeProperty(
        propertyId,
        excludePropertyId
    ) {
        return await axios.put(`${url}/property/${propertyId}/remove-exclude-property`, {
            excludePropertyId,
        });
    },
    async removeValue(id) {
        return await axios.delete(`${url}/${id}`);
    },
    async setValuePriceName(valueId, priceNameId) {
        return await axios.put(`${url}/${valueId}/set-price-name`, {
            priceNameId
        });
    },
    async removeValuePriceName(valueId) {
        return await axios.put(`${url}/${valueId}/remove-price-name`);
    },
    async retouchConfirmTruePriceNameSelected(propertyId, priceNameId) {
        return await axios.put(`${url}/retouch-confirm-true-price-name-selected/${propertyId}/set-price-name`, {
            priceNameId
        });
    },
    async removeStandardPriceName(propertyId) {
        return await axios.put(`${url}/${propertyId}/remove-standard-price-name`);
    },
    async standardPriceNameSelected(propertyId, priceNameId) {
        return await axios.put(`${url}/standard-price-name-selected/${propertyId}/set-price-name`, {
            priceNameId
        });
    },
    async retouchConfirmTruePriceNameDeSelected(propertyId) {
        return await axios.put(`${url}/retouch-confirm-true-price-name-deselected/${propertyId}`);
    },
    async setPriceModifierTargetValue(valuePriceModifierId, targetValueId) {
        return await axios.put(`${url}/price-modifier/${valuePriceModifierId}/set-target-value`, {
            targetValueId
        });
    },
    async setPriceModifierPriceName(valuePriceModifierId, priceNameId) {
        return await axios.put(`${url}/price-modifier/${valuePriceModifierId}/set-price-name`, {
            priceNameId
        });
    },
    async removePriceModifierPriceName(valuePriceModifierId) {
        return await axios.put(`${url}/price-modifier/${valuePriceModifierId}/remove-price-name`);
    },
    async deletePriceModifier(valuePriceModifierId) {
        return await axios.delete(`${url}/price-modifier/${valuePriceModifierId}`);
    },
    async addPropertyAllowedWithValues(
        propertyId,
        allowedWithValueIds
    ) {
        return await axios.put(`${url}/property/${propertyId}/add-allowed-with`, {
            allowedWithValueIds,
        });
    },
    async removePropertyAllowedWithValues(
        propertyId,
        allowedWithValueId
    ) {
        return await axios.put(`${url}/property/${propertyId}/remove-allowed-with`, {
            allowedWithValueId,
        });
    },
    async addPropertyAllowedStrictWithValues(
        propertyId,
        allowedWithValueIds
    ) {
        return await axios.put(`${url}/property/${propertyId}/add-allowed-strict-with`, {
            allowedWithValueIds,
        });
    },
    async removePropertyAllowedStrictWithValues(
        propertyId,
        allowedWithValueId
    ) {
        return await axios.put(`${url}/property/${propertyId}/remove-allowed-strict-with`, {
            allowedWithValueId,
        });
    },
    async setValuePreview(valueId, formData) {
        return await axios.post(`${url}/${valueId}/set-preview`, formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },
};
