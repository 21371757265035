import axios from "axios";

const url = "/admin/catalog";

export default {
  async catalogList() {
    return await axios.get(`${url}/list`);
  },
  async catalogTree(id) {
    return await axios.get(`${url}/${id}/tree`);
  },
  async load(id) {
    return await axios.get(`${url}/element/${id}`);
  },
  async getCatalogElementPriceNames(id) {
    return await axios.get(`${url}/element/${id}/price-name-list`);
  },
  async setIsVisible(id, isVisible) {
    return await axios.put(`${url}/element/${id}/set-is-visible`, {isVisible});
  },
};
