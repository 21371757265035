<template>
  <div class="container mt-3">
    <h1>Точки выдачи - {{ city.name }}</h1>
    <div class="mt-3 mb-4 d-flex flex-lg-row flex-column align-items-lg-end">
      <div>
        <label for="formFile">Импорт</label>
        <input id="formFile"
               ref="importCsvFile"
               class="form-control"
               name="importCsvFile"
               placeholder="Импорт"
               type="file"
               @change="importCsv">
      </div>
      <div class="ms-lg-3 mt-3 mt-lg-0">
        <label for="formFile">Экспорт</label>
        <div>
          <button class="btn btn-outline-success text-nowrap" type="button" @click.prevent="exportCsv">Скачать CSV</button>
        </div>
      </div>
      <div class="ms-lg-3 mt-3 mt-lg-0">
        <p class="m-0 fw-bold small">
          Строки без идентификаторов создают новые точки, <br> с идентификаторами обновляют существующие точки.
        </p>
      </div>
    </div>

    <table class="table table-bordered">
      <thead>
      <tr>
        <!--          <th>id</th>-->
        <th>Улица</th>
        <th>Дом</th>
        <th>Название</th>
        <th>Телефон</th>
        <th>Широта</th>
        <th>Долгота</th>
        <th>Подъезд</th>
        <th>Помещение</th>
        <th>sz_user_id</th>
        <th class="text-nowrap">В-ть</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in list" :key="item.id">
        <!--        <td>{{ item.id }}</td>-->
        <td>{{ item.streetName }}</td>
        <td>{{ item.buildingNumber }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.phone }}</td>
        <td>{{ item.latitude }}</td>
        <td>{{ item.longitude }}</td>
        <td>{{ item.entranceNumber }}</td>
        <td>{{ item.apartNumber }}</td>
        <td>{{ item.szUserId }}</td>
        <td>
          <input type="checkbox" v-model="item.isVisible" @change="changePointVisibility(item.id, item.isVisible)">
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

import api from "@/modules/Point/api";

export default {
  data() {
    return {
      importCsvFile: null,
      city: {
        name: ''
      },
      list: [],
    }
  },
  async mounted() {
    await this.fetchCity();
    await this.fetchPointList();
  },
  methods: {
    async fetchPointList() {
      const {data} = await api.listByCityId(this.$route.params.cityId);
      this.list = data;
    },
    async fetchCity() {
      const {data} = await api.getCity(this.$route.params.cityId);
      this.city = data;
    },
    async exportCsv() {
      const response = await api.exportCsvByCityId(this.$route.params.cityId);
      const type = response.headers['content-type']
      const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'import_points.csv'
      link.click()
    },
    async importCsv(event) {
      await api.importPointsCsv(event.target.files[0]);
      await this.fetchPointList();
      this.$refs.importCsvFile.value = null
    },
    async changePointVisibility(itemId, visibility) {
      console.log(visibility)
      await api.changePointVisibility(itemId, visibility);
    }
  }
}
</script>
