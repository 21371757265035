<template>
  <div class="container mt-3">
    <h1>Города</h1>
    <ul class="list-unstyled">
      <li v-for="city in topCities" :key="city.id">
        <router-link :to="{name: 'PointList', params: {cityId: city.id}}">{{city.name}}</router-link>
      </li>
    </ul>
    <ul class="list-unstyled">
      <li v-for="city in cityList" :key="city.id">
        <router-link :to="{name: 'PointList', params: {cityId: city.id}}">{{city.name}}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>

import api from "@/modules/Point/api";
import _ from 'lodash';
export default {
  data() {
    return {
      cityList: [],
    }
  },
  async mounted() {
    await this.loadCityList();
  },
  computed: {
    topCities() {
      return _.filter(this.cityList, (city) => {
        return ['Санкт-Петербург', 'Москва'].includes(city.name)
      })
    }
  },
  methods: {
    async loadCityList() {
      const {data} = await api.cityList();
      this.cityList = data;
    }
  }
}
</script>
