import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ProductTypeList from "@/modules/ProductTypes/views/ProductTypeList";
import ProductTypeEdit from "@/modules/ProductTypes/views/ProductTypeEdit";
import Prices from "@/modules/Prices/views/Prices";
import PriceList from "@/modules/Prices/views/PriceList";
import GuideItemEdit from "@/modules/Guide/views/ItemEdit";
import OrderWrapper from "@/modules/Order/views/OrderWrapper";
import OrderList from "@/modules/Order/views/OrderList";
// import PriceListEdit from "@/modules/Prices/views/PriceListEdit";
import CatalogWrapper from "@/modules/Catalog/views/CatalogWrapper";
import CatalogList from "@/modules/Catalog/views/CatalogList";
import CatalogTree from "@/modules/Catalog/views/CatalogTree";
import ElementEdit from "@/modules/Catalog/views/ElementEdit";
import CatalogPriceListGroup from "@/modules/Prices/views/CatalogPriceListGroup";
import CatalogPriceListGroupPriceNameList from "@/modules/Prices/views/CatalogPriceListGroupPriceNameList.vue";
import PriceListPrices from "@/modules/Prices/views/PriceListPrices.vue";
import GuideList from "@/modules/Guide/views/GuideList.vue";
import GuideItemList from "@/modules/Guide/views/GuideItemList.vue";
import PointWrapper from "@/modules/Point/views/PointWrapper";
import PointCityList from "@/modules/Point/views/PointCityList";
import PointList from "@/modules/Point/views/PointList";
import PackageList from "@/modules/Package/views/PackageList.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/order",
    name: "OrderWrapper",
    component: OrderWrapper,
    children: [
      {
        path: "list",
        name: "OrderList",
        component: OrderList,
      },
    ],
  },
  {
    path: "/catalog",
    name: "CatalogWrapper",
    component: CatalogWrapper,
    children: [
      {
        path: "list",
        name: "CatalogList",
        component: CatalogList,
      },
      {
        path: ":id/tree",
        name: "CatalogTree",
        component: CatalogTree,
      },
      {
        path: "element/:id",
        name: "ElementEdit",
        component: ElementEdit,
      },
    ],
  },
  {
    path: "/prices",
    name: "Prices",
    component: Prices,
    children: [
      {
        path: "catalog/:id/price-list-group",
        name: "CatalogPriceListGroup",
        component: CatalogPriceListGroup,
      },
      {
        path: "price-list-group/:id/price-names",
        name: "PriceNameList",
        component: CatalogPriceListGroupPriceNameList,
      },
      {
        path: "price-list-group/:id/price-list",
        name: "PriceList",
        component: PriceList,
      },
      {
        path: "price-list-group/:priceListGroupId/price-list/:priceListId",
        name: "PriceListPrices",
        component: PriceListPrices,
      },
    ],
  },
  {
    path: "/product-type",
    name: "ProductTypes",
    component: ProductTypeList,
  },
  {
    path: "/guide-list",
    name: "GuideList",
    component: GuideList,
  },
  {
    path: "/guide/:id",
    name: "GuideItemList",
    component: GuideItemList,
  },
  {
    path: "/guide/:guideId/item/:id",
    name: "GuideItemEdit",
    component: GuideItemEdit,
  },
  {
    path: "/product-type/:id",
    name: "ProductTypeEdit",
    component: ProductTypeEdit,
  },
  {
    path: "/point",
    name: "PointWrapper",
    component: PointWrapper,
    redirect: () => {
      return { name: "PointCityList" };
    },
    children: [
      {
        path: "city-list",
        name: "PointCityList",
        component: PointCityList,
      },
      {
        path: ":cityId/list",
        name: "PointList",
        component: PointList,
      },
    ],
  },
  {
    path: "/package",
    name: "PackageList",
    component: PackageList,
    // children: [
    //   {
    //     path: "list",
    //     name: "OrderList",
    //     component: OrderList,
    //   },
    // ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: "active",
  routes,
});

export default router;
// {
//   path: "/about",
//       name: "About",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//     import(/* webpackChunkName: "about" */ "../views/About.vue"),
// },
