<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-5">
            {{ value.item.name }}
          </div>
          <div class="col-5">
            <v-select class="style-chooser form-control-sm p-0"
                      v-model="currentPriceNameId"
                      :options="priceNames" label="name"
                      :reduce="priceName => priceName.id"
                      @option:selected="$emit('value-price-name-selected', {valueId: value.id, priceNameId: currentPriceNameId})"
            />
          </div>
          <div class="col-2">
            <a href="#" class="me-2" @click.prevent="isOptionsOpen = !isOptionsOpen">Опции</a>
            <a href="#" @click.prevent="removeValue">Удалить</a>
          </div>
        </div>
      </div>
      <ValueOptions
          class="col-12"
          v-if="isOptionsOpen"
          :price-names="priceNames"
          :product-values="productValues"
          :value="value"
          @insert-value-price-modifier="$emit('insert-value-price-modifier', $event)"
          @add-allowed-with-value-ids="$emit('add-allowed-with-value-ids', $event)"
          @remove-allowed-with-value-id="$emit('remove-allowed-with-value-id', $event)"
          @price-modifier-target-selected="$emit('price-modifier-target-selected', $event)"
          @price-modifier-price-name-selected="$emit('price-modifier-price-name-selected', $event)"
          @price-modifier-price-name-deselected="$emit('price-modifier-price-name-deselected', $event)"
          @price-modifier-delete="$emit('price-modifier-delete', $event)"
          @value-set-preview="$emit('value-set-preview', $event)"
      >
      </ValueOptions>
    </div>
  </div>
</template>
<script>
import ValueOptions from './ValueOptions'
export default {
  components: {
    ValueOptions
  },
  props: {
    value: {
      type: Object,
    },
    priceNames: {
      type: Array,
    },
    productValues: {
      type: Array,
    }
  },
  data() {
    return {
      currentPriceNameId: null,
      isOptionsOpen: false,
      watchingValue: false,
    }
  },
  watch: {
    currentPriceNameId(newVal, oldVal) {
      if (!this.watchingValue) {
        return false;
      }
      if (!newVal && newVal !== oldVal) {
        this.$emit('value-price-name-deselected', {
          valueId: this.value.id
        })
      }
    }
  },
  mounted() {
    this.currentPriceNameId = this.value.priceNameId
    this.$nextTick(() => {
      this.watchingValue = true;
    })
  },
  methods: {
    async removeValue() {
      if (!confirm('Подвердить действие')) {
        return;
      }
      this.$emit('remove-value', this.value.id)
    }
  }
}
</script>
