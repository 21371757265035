<template>
  <div class="container mt-3">
    <h1>{{ item.name }}</h1>
    <div class="groups">
      <div
          v-for="(fieldGroup, index) in item.fieldGroups"
          :key="fieldGroup.id"
          class="group mb-5"
      >
        <legend>{{ fieldGroup.name }}</legend>
        <div :id="'sortable-fields' + index" class="row">
          <div
              v-for="(field) in fieldGroup.fields"
              :key="field.id"
              :data-field-id="field.id"
              class="col-12 drag-item"
          >
            <div class="d-flex align-items-center flex-shrink-0 mb-3">
              <div>
                <div class="drag-handle cursor-pointer py-2" style="width: 30px;">
                  <svg class="bi bi-grip-horizontal" fill="currentColor" height="16" viewBox="0 0 16 16"
                       width="16" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                </div>
              </div>
              <div class="w-100">
                <div>{{ field.name }}</div>
                <CompositeField
                    v-if="field.type === 'CompositeField'"
                    :field="field"
                    @composite-field-guide-change="inCompositeFieldGuideChange"
                    @on-field-deleting="deleteField"
                ></CompositeField>
                <AttachmentField
                    v-if="field.type === 'AttachmentField'"
                    :field="field"
                    @on-field-deleting="deleteField"
                ></AttachmentField>
                <FullNameDateField
                    v-if="field.type === 'FullNameDateField'"
                    :field="field"
                    @on-field-deleting="deleteField"
                ></FullNameDateField>
                <StandardPriceField
                    v-if="field.type === 'StandardPriceField'"
                    :field="field"
                    @on-field-deleting="deleteField"
                ></StandardPriceField>
                <div class="row mt-1">
                  <div class="col-md-8">
                    <input type="text" class="form-control" :value="field.helpMessage" @blur.prevent="setHelpMessage(field.id, $event)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="alert alert-primary" role="alert">
            <h5>Добавить поле</h5>
            <FieldAddForm
                :fieldGroupId="fieldGroup.id"
                :guides="guides"
                class="col-12"
                @field-creating="onFieldCreating(fieldGroup.id, $event)"
            ></FieldAddForm>
          </div>

        <!--        <div class="border p-1">-->
        <!--          <input type="text" class="form-control form-control-sm">-->
        <!--        </div>-->
        <!--        <div class="border p-1">-->
        <!--          <input type="text" class="form-control form-control-sm">-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/modules/ProductTypes/api";
import CompositeField from "@/modules/Field/components/CompositeField";
import AttachmentField from "@/modules/Field/components/AttachmentField";
import FullNameDateField from "@/modules/Field/components/FullNameDateField";
import StandardPriceField from "@/modules/Field/components/StandardPriceField";
import FieldAddForm from "@/modules/ProductTypes/components/FieldAddForm";
import {mapActions, mapGetters} from "vuex";
import Sortable from 'sortablejs';
import _ from 'lodash';

export default {
  components: {
    CompositeField,
    AttachmentField,
    FullNameDateField,
    FieldAddForm,
    StandardPriceField,
  },
  data() {
    return {
      item: {
        fieldGroups: [],
      },
    };
  },
  computed: {
    ...mapGetters("guideModule", {
      guides: "getGuides",
    }),
  },
  async mounted() {
    await this.loadGuides();
    await this.loadItem();
    const el = document.getElementById('sortable-fields0');
    this.$nextTick(() => {
      Sortable.create(el, {
        handle: ".drag-handle",
        draggable: ".drag-item",  // Specifies which items inside the element should be draggable
        onEnd: function (/**Event*/evt) {
          console.log(evt.srcElement)
          const fieldIds = {};
          _.each(evt.srcElement.children, (child, index) => {
            fieldIds[child.dataset.fieldId] = index;
            console.log(child.dataset.fieldId, index)
          })
          console.log(fieldIds)
          api.fieldOrderChange(fieldIds)
        },
      });
    })
  },
  methods: {
    ...mapActions("guideModule", ["loadGuides"]),
    async loadItem() {
      const {data} = await api.load(this.$route.params.id);
      this.item = data;
    },
    // async setHelpMessage(fieldId, event) {
    //   console.log(fieldId, event.target.value)
    // },
    async setHelpMessage(fieldId, event) {
      const {data} = await api.setHelpMessage(fieldId, event.target.value);
      console.log(data)
    },
    async inCompositeFieldGuideChange({fieldId, guideId}) {
      await api.compositeFieldGuideChange(fieldId, guideId);
    },
    async onFieldCreating(fieldGroupId, field) {
      await api.fieldInsert(fieldGroupId, field);
      await this.loadItem();
    },
    async deleteField(field) {
      if (!confirm('Подтвердите')) {
        return false;
      }

      if (!confirm(`Удаление поля ${field.name} также удалит связанные свойства товаров`)) {
        return false;
      }

      await api.deleteField(field.id);
      await this.loadItem();
    }
  },
};
</script>
