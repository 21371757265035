<template>
  <div class="container mt-3">
    <h1>Заказы</h1>
    <DxDataGrid
        :data-source="list"
        :show-row-lines="true"
        :show-borders="true"
        :expanded-row-keys="expandedRowKeys"
        :column-auto-width="true"
        key-expr="id"
        parent-id-expr="parentId"
    >
      <DxEditing
          :allow-updating="false"
          :allow-deleting="false"
          :allow-adding="false"
          mode="row"
      />

      <DxRowDragging
          :allow-drop-inside-item="allowDropInsideItem"
          :allow-reordering="allowReordering"
          :show-drag-icons="showDragIcons"
      />
      <DxColumn data-field="sferaNumber" caption="Номер"/>
      <DxColumn data-field="id" caption="Id"/>
      <DxColumn data-field="shopOrderClientContact" caption="Клиент"/>
      <DxColumn data-field="amount" caption="Цена"/>
      <DxColumn data-field="name" caption="Название"/>

<!--      <DxColumn type="buttons">-->
<!--        <DxButton name="save"/>-->
<!--        <DxButton name="cancel"/>-->
<!--        <DxButton name="delete"/>-->
<!--      </DxColumn>-->
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxColumn,
  DxRowDragging,
  DxDataGrid,
  // DxButton,
  DxEditing,
} from "devextreme-vue/data-grid";
import api from "@/modules/Order/api";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxRowDragging,
    // DxButton,
    DxEditing,
  },
  data() {
    return {
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      list: [],
    };
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    // toItemList(event) {
    //   this.$router.push({
    //     name: "GuideItemList",
    //     params: { id: event.row.data.id },
    //   });
    // },
    // async onInserting({ data }) {
    //   console.log(data)
    //   // await api.insert(data);
    //   return false;
    // },
    // async create() {
    //   // await api.create("test");
    //   return false;
    // },
    async getList() {
      const {data} = await api.list();
      this.list = data;
    },
    // async loadTypes() {
    //   const { data } = await api.getGuideTypes();
    //   this.types = data;
    // },
  },
};
</script>
