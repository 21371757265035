import axios from "axios";

const url = "/admin/order";

export default {
  async list() {
    return await axios.get(url);
  },
  // async listByCityId(cityId) {
  //   return await axios.get(`${url}/${cityId}`);
  // },
  // async exportCsvByCityId(cityId) {
  //   return await axios.get(`${url}/by-city/${cityId}/export-csv`, {
  //     responseType: 'blob', // Important
  //   });
  // },
  // async importPointsCsv(file) {
  //   const formData = new FormData();
  //   formData.append("import-csv", file);
  //   return await axios.post(`${url}/import-csv`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   })
  // },
  // async cityList() {
  //   return await axios.get('/admin/geo/city');
  // },
  // async getCity(id) {
  //   return await axios.get(`/admin/geo/city/${id}`);
  // },
};
