<template>
  <div>
    <PropertyOptions
        v-if="property && isOpenPropertyOptions"
        :product-values="productValues"
        :property="property"
        class="mb-3"
        @add-property-allowed-with-value-ids="$emit('add-property-allowed-with-value-ids', $event)"
        @remove-property-allowed-with-value-id="$emit('remove-property-allowed-with-value-id', $event)"
    ></PropertyOptions>
  </div>
</template>

<script>
import PropertyOptions from "@/modules/Catalog/components/Product/PropertyOptions";

export default {
  components: {
    PropertyOptions,
  },
  props: {
    field: {
      type: Object,
    },
    property: {
      type: Object,
    },
    priceNames: {
      type: Array,
    },
    productValues: {
      type: Array,
    },
    isOpenPropertyOptions: {
      type: Boolean
    },
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
