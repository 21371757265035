<template>
  <div>
    <span class="text-secondary">Модификатор цен</span>
    <ValuePriceModifier
        v-for="(valuePriceModifier) in value.valuePriceModifiers"
        :key="valuePriceModifier.id"
        :price-names="priceNames"
        :product-values="productValues"
        :valuePriceModifier="valuePriceModifier"
        class="mb-1"
        @price-modifier-target-selected="$emit('price-modifier-target-selected', $event)"
        @price-modifier-price-name-selected="$emit('price-modifier-price-name-selected', $event)"
        @price-modifier-price-name-deselected="$emit('price-modifier-price-name-deselected', $event)"
        @price-modifier-delete="$emit('price-modifier-delete', $event)"
    >
    </ValuePriceModifier>
    <div class="row">
      <div class="col-5">
        <v-select v-model="currentValuePriceModifierTargetValueId" :options="productValues" :reduce="val => val.id"
                  class="style-chooser form-control-sm p-0" label="name"/>
      </div>
      <div class="col-5">
        <v-select v-model="currentValuePriceModifierPriceNameId" :options="priceNames"
                  :reduce="priceName => priceName.id"
                  class="style-chooser form-control-sm p-0" label="name"/>
      </div>
      <div class="col-2">
        <a href="#" @click.prevent="insertValuePriceModifier">Добавить модифиактор</a>
      </div>
    </div>
  </div>
  <AllowedWithValues
      :allowed-with-ids="allowedWithIds"
      :product-values="productValues"
      @add-allowed-with-value-ids="$emit('add-allowed-with-value-ids', {...$event, valueId: value.id})"
      @remove-allowed-with-value-id="$emit('remove-allowed-with-value-id', {...$event, valueId: value.id})"
  ></AllowedWithValues>
  <span class="text-secondary mt-0">Изобржание</span>
  <div class="row">
    <div class="col-lg-6">
      <input id="i3" :ref="'valueImage' + value.id" accept="image/*"
             class="form-control"
             type="file"
             @change="$emit('value-set-preview', {valueId: value.id, file: $refs['valueImage' + value.id].files[0]})">
    </div>
    <div class="col-lg-6">
      <img
          v-if="value.preview && value.preview.filePath"
          :src="staticHost + value.preview.filePath"
          style="max-width: 200px; max-height: 50px;"
          alt="">
    </div>
    <!--    <div class="col-lg-6 mt-3" v-if="element.photo && element.photo.filePath">-->
    <!--      <img :src="staticHost + element.photo.filePath" alt="" class="img-fluid" style="max-height: 170px">-->
    <!--    </div>-->
  </div>
</template>
<script>
import ValuePriceModifier from './ValuePriceModifier'
import AllowedWithValues from './AllowedWithValues'
const staticHost = process.env.VUE_APP_STATIC_HOST;
export default {
  components: {
    ValuePriceModifier,
    AllowedWithValues
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    priceNames: {
      type: Array,
    },
    productValues: {
      type: Array,
    }
  },
  data() {
    return {
      staticHost,
      currentValuePriceModifierTargetValueId: null,
      currentValuePriceModifierPriceNameId: null,
      allowedWithIds: []
    }
  },
  mounted() {
    this.allowedWithIds = this.value.allowedWithIds
  },
  methods: {
    async insertValuePriceModifier() {
      await this.$emit('insert-value-price-modifier', {
        valueId: this.value.id,
        currentValuePriceModifierTargetValueId: this.currentValuePriceModifierTargetValueId,
        currentValuePriceModifierPriceNameId: this.currentValuePriceModifierPriceNameId,
      })
      this.currentValuePriceModifierTargetValueId = null;
      this.currentValuePriceModifierPriceNameId = null;
    },
    async onImageUpload() {
      // if (this.$refs.imageFile.files[0]) {
      //   console.log(this.$refs.imageFile.files[0])
      //   let formData = new FormData();
      //   formData.append('file', this.$refs.imageFile.files[0]);
      //   await hierarchyApi.setElementPreview(this.$route.params.id, formData)
      //   await this.fetchElement();
      // }
    }
  }
}
</script>
