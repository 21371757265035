<template>
  <div class="container mt-3">
    <h1>Наименование группы: {{ priceListGroup.name }}</h1>
    <h5 class="mb-4">Каталоги: {{ catalogNames }}</h5>
    <div class="d-flex">
      <div>
        <label for="formFile">Импорт</label>
        <input id="formFile"
               ref="importCsvFile"
               class="form-control"
               name="importCsvFile"
               placeholder="Импорт"
               type="file"
               @change="importCsv">
      </div>
      <div class="ms-3">
        <label>Экспорт</label>
        <div>
          <a href="#" class="btn btn-outline-success" @click.prevent="exportPriceNamesCsvByCityId">Скачать CSV</a>
        </div>
      </div>
    </div>
    <DxDataGrid
        :data-source="priceListGroup.priceNames"
        :remote-operations="false"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :show-borders="true"
        :column-auto-width="true"
        :expanded-row-keys="expandedRowKeys"
        key-expr="id"
        @row-inserting="onInserting"
        @row-updating="onUpdating"
    >
      <DxEditing
          :allow-updating="true"
          :allow-deleting="false"
          :allow-adding="true"
          mode="row"
      />

      <DxColumn data-field="name" caption="Название"/>

      <DxColumn type="buttons">
        <DxButton name="edit"/>
        <DxButton name="delete"/>
      </DxColumn>
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxEditing,
} from "devextreme-vue/data-grid";
import api from "@/modules/Prices/api";
import apiCatalog from "@/modules/Catalog/api";
import _ from "lodash";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxEditing,
  },
  data() {
    return {
      catalogPriceListGroups: [],
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      catalog: [],
      priceListGroup: {},
      calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        if (target === "search" && typeof filterValue === "string") {
          return [this.dataField, "contains", filterValue];
        }
        return function (data) {
          return (data.catalogElementIds || []).indexOf(filterValue) !== -1;
        };
      },
    };
  },
  computed: {
    catalogNames() {
      if (this.priceListGroup && this.priceListGroup.catalogElement) {
        return _.map(this.priceListGroup.catalogElement, "title").join(", ");
      }
      return "";
    },
  },
  async mounted() {
    await this.loadPriceListGroup();
  },
  methods: {
    async importCsv(event) {
      await api.importPriceNamesCsv(this.$route.params.id, event.target.files[0]);
      await this.loadPriceListGroup();
      this.$refs.importCsvFile.value = null
    },
    async exportPriceNamesCsvByCityId() {
      const response = await api.exportPriceNamesCsvByCityId(this.$route.params.id);
      const type = response.headers['content-type']
      const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'import_price_names.csv'
      link.click()
    },
    onValueChanged(value, cellInfo) {
      cellInfo.setValue(value);
      cellInfo.component.updateDimensions();
    },
    cellTemplate(container, options) {
      const noBreakSpace = "\u00A0";
      const text = (options.value || [])
          .map((element) => options.column.lookup.calculateCellValue(element))
          .join(", ");
      container.textContent = text || noBreakSpace;
      container.title = text;
    },
    async onInserting({data}) {
      await api.insertPriceName(this.$route.params.id, data);
      await this.loadPriceListGroup();
    },
    async onUpdating(data) {
      await api.updatePriceName(data.oldData.id, data.newData);
    },
    async loadPriceListGroup() {
      const {data} = await api.loadPriceListGroup(this.$route.params.id);
      this.priceListGroup = data;
    },
    async loadCatalog() {
      const {data} = await apiCatalog.load(this.$route.params.id);
      this.catalog = data;
    },
  },
};
</script>
