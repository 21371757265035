<template>
    <div class="d-flex align-items-center row">
      <div class="col-md-8">
        <select class="form-select" v-model="guideId" @change="$emit('composite-field-guide-change', {fieldId: field.id, guideId})">
          <option value=""></option>
          <option :value="guide.id" v-for="guide in guides" :key="guide.id">
            {{ guide.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <div class="btn-group" role="group">
            <button id="btnGroupDrop1" type="button" class="btn btn-outline-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Действия
            </button>
            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <li><a class="dropdown-item" href="#">Сохранить</a></li>
              <li><a class="dropdown-item" href="#">Удалить значения других справочников</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="$emit('on-field-deleting', field)">Удалить</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  props: {
    field: {
      type: Object,
    },
  },
  data() {
    return {
      guideId: null,
    };
  },
  computed: {
    ...mapGetters("guideModule", {
      guides: "getGuides",
    }),
  },
  async mounted() {
    await this.detectGuideId();
  },
  methods: {
    async detectGuideId() {
      const guide = await _.find(this.guides, { id: this.field.guide.id });
      if (guide) {
        this.guideId = guide.id;
      }
    },
  },
};
</script>
