<template>
  <form action="">
    <div class="row">
      <div class="col-6">
        <label for="width">Ширина</label>
        <input type="text" class="form-control" id="width" v-model="currentItem.width">
      </div>
      <div class="col-6">
        <label for="height">Высота</label>
        <input type="text" class="form-control" id="height" v-model="currentItem.height">
      </div>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    }
  },
  data() {
    return {
      currentItem: {}
    }
  },
  mounted() {
    this.currentItem = this.item;
  }
}
</script>
