<template>
  <form @submit.prevent="$emit('guide-item-persist', currentItem)">
    <div class="row">
      <div class="col-6">
        <label for="width">Ширина</label>
        <input
          type="text"
          class="form-control"
          id="width"
          v-model="currentItem.width"
        />
      </div>
      <div class="col-6">
        <label for="height">Высота</label>
        <input
          type="text"
          class="form-control"
          id="height"
          v-model="currentItem.height"
        />
      </div>
      <div class="col-6">
        <label for="diameter">Диаметер</label>
        <input
          type="text"
          class="form-control"
          id="diameter"
          v-model="currentItem.diameter"
        />
      </div>
      <div class="col-6">
        <label for="thickness">Толщина</label>
        <input
          type="text"
          class="form-control"
          id="thickness"
          v-model="currentItem.thickness"
        />
      </div>
    </div>
    <h3 class="mt-4">Размеры посылки</h3>
    <div class="row">
      <div class="col-6">
        <label for="diameter">Ширина посылки</label>
        <input
          type="text"
          class="form-control"
          id="diameter"
          v-model="currentItem.packageSize.width"
        />
      </div>
      <div class="col-6">
        <label for="height1">Высота посылки</label>
        <input
          type="text"
          class="form-control"
          id="height1"
          v-model="currentItem.packageSize.height"
        />
      </div>
      <div class="col-6">
        <label for="thickness2">Глубина посылки</label>
        <input
          type="text"
          class="form-control"
          id="thickness2"
          v-model="currentItem.packageSize.depth"
        />
      </div>
      <div class="col-6">
        <label for="thickness2">Вес посылки</label>
        <input
          type="text"
          class="form-control"
          id="thickness2"
          v-model="currentItem.packageSize.weight"
        />
      </div>
      <div class="col-6">
        <label for="thickness3">Вес брутто посылки</label>
        <input
          type="text"
          class="form-control"
          id="thickness3"
          v-model="currentItem.packageSize.grossWeight"
        />
      </div>
      <div class="col-6">
        <label for="thickness4">Артикул</label>
        <input
          type="text"
          class="form-control"
          id="thickness4"
          v-model="currentItem.packageSize.wareKey"
        />
      </div>
    </div>
    <div class="col-12 mt-3">
      <button class="btn btn-outline-success">Сохранить</button>
    </div>
  </form>
</template>
<script>
import _ from "lodash";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      masterPackageSize: {},
      currentItem: {
        packageSize: {
          height: 0,
          width: 0,
          weight: 0,
          grossWeight: 0,
          wareKey: "",
          depth: 0,
        },
      },
    };
  },
  mounted() {
    // this.currentItem.packageSize = Object.assign({}, this.currentItem.packageSize);
    console.log(this.item);
    // this.currentItem = _.assign(this.currentItem, this.item)
    let o = _.cloneDeep(this.item);
    if (!o.packageSize) {
      o.packageSize = _.cloneDeep(this.currentItem.packageSize);
    }
    this.currentItem = _.defaultsDeep(o, this.currentItem);
    // this.currentItem.packageSize = _.defaultsDeep(this.currentItem.packageSize, this.item.packageSize)
    console.log(this.currentItem);
    // this.currentItem = Object.assign({}, this.currentItem, this.item);
    // if (!this.currentItem.packageSize) {
    //   this.currentItem.packageSize = {
    //     height: 0,
    //     width: 0,
    //     weight: 0,
    //     grossWeight: 0,
    //     wareKey: '',
    //   }
    // }
  },
};
</script>
