import axios from "axios";

const url = "/admin/hierarchy";

export default {
    async setElementPreview(elementId, formData) {
        return await axios.post(`${url}/element/${elementId}/set-preview`, formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },
};
