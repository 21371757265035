<template>
  <div>
    <div class="row mb-1">
      <div class="col-5">
        <v-select v-model="targetValueId" :clearable="false"
                  :options="productValues"
                  :reduce="val => val.id"
                  class="style-chooser form-control-sm p-0"
                  label="name"
                  @option:selected="$emit('price-modifier-target-selected', {valuePriceModifierId: valuePriceModifier.id, targetValueId})"
        />
      </div>
      <div class="col-5">
        <v-select
            v-model="priceNameId"
            :options="priceNames"
            :reduce="priceName => priceName.id"
            class="style-chooser form-control-sm p-0"
            label="name"
            @option:selected="$emit('price-modifier-price-name-selected', {valuePriceModifierId: valuePriceModifier.id, priceNameId})"
        />
      </div>
      <div class="col-2">
        <a href="#"
           @click.prevent="$emit('price-modifier-delete', {valuePriceModifierId: valuePriceModifier.id})"
        >Удалить</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    valuePriceModifier: {
      type: Object
    },
    priceNames: {
      type: Array,
    },
    productValues: {
      type: Array,
    }
  },
  data() {
    return {
      targetValueId: null,
      priceNameId: null,
      watchingTargetValueId: false,
      watchingPriceNameId: false,
    }
  },
  watch: {
    targetValueId(newVal, oldVal) {
      if (!this.watchingTargetValueId) {
        return false;
      }
      if (!newVal && newVal !== oldVal) {
        this.$emit('price-modifier-target-deselected', {
          valuePriceModifierId: this.valuePriceModifier.id
        })
      }
    },
    priceNameId(newVal, oldVal) {
      if (!this.watchingPriceNameId) {
        return false;
      }
      if (!newVal && newVal !== oldVal) {
        this.$emit('price-modifier-price-name-deselected', {
          valuePriceModifierId: this.valuePriceModifier.id
        })
      }
    },
  },
  mounted() {
    this.targetValueId = this.valuePriceModifier.targetValueId
    this.priceNameId = this.valuePriceModifier.priceNameId
    this.$nextTick(() => {
      this.watchingTargetValueId = true;
      this.watchingPriceNameId = true;
    })
  }
}
</script>
