<template>
  <div>
    <PropertyOptions
        v-if="property && isOpenPropertyOptions"
        :product-values="productValues"
        :property="property"
        class="mb-3"
        @add-property-allowed-with-value-ids="$emit('add-property-allowed-with-value-ids', $event)"
        @remove-property-allowed-with-value-id="$emit('remove-property-allowed-with-value-id', $event)"
    ></PropertyOptions>
    <div class="row">
      <div class="col-md-4 col-lg-3">
        <label :for="'allowedNumberAttachments' + property.id">Количество прикрепляемых файлов</label>
        <input
            :id="'allowedNumberAttachments' + property.id"
            v-model="allowedNumberAttachments"
            class="form-control"
            type="number"
            @change="$emit('allowed-number-attachments-change', {propertyId: property.id, allowedNumberAttachments})"
        >
      </div>
    </div>
  </div>
</template>

<script>
import PropertyOptions from "@/modules/Catalog/components/Product/PropertyOptions";

export default {
  components: {
    PropertyOptions,
  },
  props: {
    field: {
      type: Object,
    },
    property: {
      type: Object,
    },
    priceNames: {
      type: Array,
    },
    productValues: {
      type: Array,
    },
    isOpenPropertyOptions: {
      type: Boolean
    },
  },
  data() {
    return {
      allowedNumberAttachments: 0,
    }
  },
  mounted() {
    this.allowedNumberAttachments = this.property.allowedNumberAttachments
  },
  methods: {}
}
</script>
