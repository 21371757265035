<template>
  <div>
    <span class="text-secondary">
      <slot name="title">Для значений</slot>
    </span>
    <v-select
      v-model="currentAllowedWithIds"
      :options="productValues"
      :reduce="(val) => val.id"
      class="style-chooser form-control-sm p-0"
      label="name"
      multiple
      @option:selected="addAllowedWithValues"
      @option:deselected="removeAllowedWithValue"
    />
  </div>
</template>
<script>
import _ from "lodash";

export default {
  props: {
    productValues: {
      type: Array,
    },
    allowedWithIds: {
      type: Array,
    },
  },
  data() {
    return {
      currentAllowedWithIds: [],
    };
  },
  async mounted() {
    await this.$nextTick(() => {
      this.currentAllowedWithIds = _.clone(this.allowedWithIds);
    });
  },
  methods: {
    async addAllowedWithValues(selected) {
      const allowedWithValueIds = _.map(selected, (s) => {
        return s.id;
      });
      await this.$emit("add-allowed-with-value-ids", {
        allowedWithValueIds,
      });
    },
    async removeAllowedWithValue(deselected) {
      await this.$emit("remove-allowed-with-value-id", {
        allowedWithValueId: (deselected.id ? deselected.id : deselected),
      });
    },
  },
};
</script>
