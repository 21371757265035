<template>
  <div class="container mt-3">
    <h1>Каталоги</h1>
    <DxDataGrid
      :data-source="catalogList"
      :remote-operations="false"
      :allow-column-reordering="true"
      :row-alternation-enabled="true"
      :show-borders="true"
      :column-auto-width="true"
      :expanded-row-keys="expandedRowKeys"
      key-expr="id"
      @row-inserting="onInserting"
      @row-updating="onUpdating"
    >
      <DxEditing
        :allow-updating="false"
        :allow-deleting="false"
        :allow-adding="true"
        mode="row"
      />

      <DxColumn data-field="title" caption="Название" />

<!--      <DxColumn-->
<!--        :width="200"-->
<!--        :allow-sorting="false"-->
<!--        :cell-template="cellTemplate"-->
<!--        :calculate-filter-expression="calculateFilterExpression"-->
<!--        data-field="catalogElementIds"-->
<!--        caption="Каталоги"-->
<!--        edit-cell-template="tagBoxEditor"-->
<!--      >-->
<!--        <DxLookup-->
<!--          :data-source="catalogList"-->
<!--          value-expr="id"-->
<!--          display-expr="title"-->
<!--        />-->
<!--      </DxColumn>-->

      <template #tagBoxEditor="{ data: cellInfo }">
        <CatalogTagBox
          :value="cellInfo.value"
          :on-value-changed="(value) => onValueChanged(value, cellInfo)"
          :data-source="catalogList"
          :data-grid-component="cellInfo.component"
        />
      </template>

      <DxColumn type="buttons">
        <DxButton name="edit" />
        <DxButton text="Структура" :on-click="toTree" />
        <DxButton text="Группы прайслистов" :on-click="toPriceListGroups" />
        <DxButton name="delete" />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxButton,
  DxEditing,
  // DxLookup,
  // DxRequiredRule,
} from "devextreme-vue/data-grid";
import api from "@/modules/Prices/api";
import apiCatalog from "@/modules/Catalog/api";
import CatalogTagBox from "@/modules/Prices/components/CatalogTagBox";

export default {
  components: {
    // DxRequiredRule,
    // DxLookup,
    CatalogTagBox,
    DxDataGrid,
    DxColumn,
    DxButton,
    DxEditing,
  },
  data() {
    return {
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      catalogList: [],
      calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        if (target === "search" && typeof filterValue === "string") {
          return [this.dataField, "contains", filterValue];
        }
        return function (data) {
          return (data.catalogElementIds || []).indexOf(filterValue) !== -1;
        };
      },
    };
  },
  async mounted() {
    await this.loadCatalogList();
    // await this.loadItems();
  },
  methods: {
    onValueChanged(value, cellInfo) {
      cellInfo.setValue(value);
      cellInfo.component.updateDimensions();
    },
    cellTemplate(container, options) {
      const noBreakSpace = "\u00A0";
      const text = (options.value || [])
        .map((element) => options.column.lookup.calculateCellValue(element))
        .join(", ");
      container.textContent = text || noBreakSpace;
      container.title = text;
    },
    toTree(event) {
      this.$router.push({
        name: "CatalogTree",
        params: { id: event.row.data.id },
      });
    },
    toPriceListGroups(event) {
      this.$router.push({
        name: "CatalogPriceListGroup",
        params: { id: event.row.data.id },
      });
    },
    async onInserting({ data }) {
      await api.insert(data);
      // await this.loadItems();
    },
    async onUpdating(data) {
      await api.update(data.oldData.id, data.newData);
    },
    async loadItems() {
      const { data } = await api.list();
      this.list = data;
    },
    async loadCatalogList() {
      const { data } = await apiCatalog.catalogList();
      this.catalogList = data;
    },
  },
};
</script>
