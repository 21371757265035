<template>
  <div class="mb-5">
    <div class="border-bottom">
      <nav class="nav container">
        <router-link class="nav-link" :to="{ name: 'CatalogList' }"
          >Каталоги</router-link
        >
        <router-link class="nav-link" :to="{ name: 'ProductTypes' }"
          >Типы товаров</router-link
        >
        <router-link class="nav-link" :to="{ name: 'GuideList' }"
          >Справочники</router-link
        >
        <router-link class="nav-link" :to="{ name: 'PointWrapper' }"
          >Точки выдачи</router-link
        >
        <router-link class="nav-link" :to="{ name: 'OrderList' }"
          >Заказы</router-link
        >
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Прочее</a>
          <ul class="dropdown-menu">
            <router-link class="dropdown-item" :to="{ name: 'PackageList' }"
            >Упаковки</router-link
            >
          </ul>
        </li>
<!--        <router-link class="nav-link" :to="{ name: 'PriceList' }"-->
<!--          >Прайслисты</router-link-->
<!--        >-->
      </nav>
    </div>
    <router-view />
  </div>
</template>
<script>
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
export default {
  created() {
    loadMessages(ruMessages);
    locale(navigator.language);
  },
};
</script>
<style lang="scss">
.nav {
  .nav-link.active {
    font-weight: bold;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
