import axios from "axios";

const url = "/admin/prices/price-list";

export default {
  async exportPriceNamesCsvByCityId(priceListGroupId) {
    return await axios.get(`${url}/by-price-list-group/${priceListGroupId}/export-csv`, {
      responseType: 'blob', // Important
    });
  },
  async importPriceNamesCsv(priceListGroupId, file) {
    const formData = new FormData();
    formData.append("import-csv", file);
    return await axios.post(`${url}/${priceListGroupId}/import-csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async exportPriceListsCsv() {
    return await axios.get(`${url}/export-csv/price-lists`, {
      responseType: 'blob', // Important
    });
  },
  async importPriceListsCsv(file) {
    const formData = new FormData();
    formData.append("import-csv", file);
    return await axios.post(`${url}/import-csv/price-lists`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async exportPriceListPricesCsv(priceGroupId, priceListId) {
    return await axios.get(`${url}/${priceGroupId}/${priceListId}/export-csv/price-list/prices`, {
      responseType: 'blob', // Important
    });
  },
  async importPriceListPricesCsv(priceListId, file) {
    const formData = new FormData();
    formData.append("import-csv", file);
    return await axios.post(`${url}/${priceListId}/import-csv/price-list/prices`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async list() {
    return await axios.get(url);
  },
  async priceItemList(id) {
    return await axios.get(`${url}/${id}/item`);
  },
  async catalogPriceListGroups(id) {
    return await axios.get(`${url}/catalog/${id}/price-list-group`);
  },
  async insert(data) {
    return await axios.post(url, data);
  },
  async update(id, newData) {
    return await axios.put(`${url}/${id}`, newData);
  },
  async load(id) {
    return await axios.get(`${url}/${id}`);
  },
  async insertPriceItem(priceListId, data) {
    return await axios.post(`${url}/${priceListId}/item`, data);
  },
  async updatePriceItem(id, data) {
    return await axios.put(`${url}/item/${id}`, data);
  },
  async loadPriceListGroup(id) {
    return await axios.get(`/admin/prices/price-list-group/${id}`);
  },
  async insertPriceName(priceListGroup, data) {
    return await axios.post(`/admin/prices/price-name/${priceListGroup}`, data);
  },
  async updatePriceName(priceNameId, data) {
    return await axios.put(`/admin/prices/price-name/${priceNameId}`, data);
  },
  async loadPriceLists() {
    return await axios.get(`${url}`);
  },
  async insertPriceList(data) {
    return await axios.post(`/admin/prices/price-list`, data);
  },
  async updatePriceList(id, data) {
    return await axios.put(`/admin/prices/price-list/${id}`, data);
  },
  async loadPriceListPrices(priceListGroupId, priceListId) {
    return await axios.get(`${url}/${priceListGroupId}/${priceListId}`);
  },
  async updatePrice(priceId, data) {
    return await axios.put(`/admin/prices/price/${priceId}/value`, data);
  },
};
