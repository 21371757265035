<template>
  <div>
    <img v-if="image.filePath" :src="staticHost + image.filePath" class="img-fluid" alt="">
  </div>
</template>
<script>
const staticHost = process.env.VUE_APP_STATIC_HOST;
export default {
  props: {
    image: {
      type: Object,
      default() {
        return {
          filePath: null
        }
      }
    }
  },
  data() {
    return {
      staticHost
    }
  }
}
</script>
