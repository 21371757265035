<template>
  <form @submit.prevent="$emit('field-creating', field) && initMaster()">
    <div class="row d-flex align-items-end">
      <div class="col-md-6 col-lg-4">
        <label :for="'name' + fieldGroupId">Название</label>
        <input :id="'name' + fieldGroupId"
               v-model="field.name"
               class="form-control"
               placeholder="Название"
               required="required"
               type="text"
        >
      </div>
      <div class="col-md-6 col-lg-4">
        <label :for="'fieldType' + fieldGroupId">Тип поля</label>
        <select
            :id="'fieldType' + fieldGroupId"
            v-model="field.type"
            class="form-select"
            required="required"
        >
          <option value="CompositeField">Составное поле</option>
          <option value="AttachmentField">Прикрепляемые файлы</option>
          <option value="FullNameDateField">Имя и дата</option>
          <option value="RetouchConfirmationField">Подтверждение макета</option>
          <option value="CommentField">Комментарий</option>
          <option value="StandardPriceField">Стандартная цена</option>
        </select>
      </div>
      <div class="col-auto">
        <button class="btn btn-outline-primary">Добавить</button>
      </div>
    </div>
    <div v-if="isGuideNeed" class="row mt-2">
      <div class="col-md-6 col-lg-4"></div>
      <div class="col-md-6 col-lg-4">
        <label :for="'guide' + fieldGroupId">Справочник</label>
        <select
            :id="'guide' + fieldGroupId"
            v-model="field.guideId"
            class="form-select"
            required="required"
        >
          <option v-for="guide in guides" :key="guide.id" :value="guide.id">{{ guide.name }}</option>
        </select>
      </div>
    </div>
  </form>
</template>
<script>
const master = {
  name: null,
  type: null,
  guideId: null,
};
export default {
  props: {
    guides: {
      type: Array
    },
    fieldGroupId: {
      type: Number
    }
  },
  data() {
    return {
      field: {}
    }
  },
  mounted() {
    this.initMaster();
  },
  computed: {
    isGuideNeed() {
      return this.field.type === 'CompositeField';
    }
  },
  methods: {
    initMaster() {
      this.field = Object.assign({}, master);
    }
  }
}
</script>
