<template>
  <div class="container mt-3">
    <h1>Группы прайслистов: {{ catalog.title }}</h1>
    <DxDataGrid
      :data-source="catalogPriceListGroups"
      :remote-operations="false"
      :allow-column-reordering="true"
      :row-alternation-enabled="true"
      :show-borders="true"
      :column-auto-width="true"
      :expanded-row-keys="expandedRowKeys"
      key-expr="id"
      @row-inserting="onInserting"
      @row-updating="onUpdating"
    >
      <DxEditing
        :allow-updating="false"
        :allow-deleting="false"
        :allow-adding="false"
        mode="row"
      />

      <DxColumn data-field="name" caption="Название" />

      <DxColumn type="buttons">
        <DxButton name="edit" />
        <DxButton text="Наименования" :on-click="toPriceName" />
        <DxButton text="Прайслисты" :on-click="toPriceList" />
        <DxButton name="delete" />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxButton,
  DxEditing,
  // DxRequiredRule,
} from "devextreme-vue/data-grid";
import api from "@/modules/Prices/api";
import apiCatalog from "@/modules/Catalog/api";

export default {
  components: {
    // DxRequiredRule,
    DxDataGrid,
    DxColumn,
    DxButton,
    DxEditing,
  },
  data() {
    return {
      catalogPriceListGroups: [],
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      catalog: [],
      calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        if (target === "search" && typeof filterValue === "string") {
          return [this.dataField, "contains", filterValue];
        }
        return function (data) {
          return (data.catalogElementIds || []).indexOf(filterValue) !== -1;
        };
      },
    };
  },
  async mounted() {
    await this.loadCatalog();
    await this.loadItems();
  },
  methods: {
    onValueChanged(value, cellInfo) {
      cellInfo.setValue(value);
      cellInfo.component.updateDimensions();
    },
    cellTemplate(container, options) {
      const noBreakSpace = "\u00A0";
      const text = (options.value || [])
        .map((element) => options.column.lookup.calculateCellValue(element))
        .join(", ");
      container.textContent = text || noBreakSpace;
      container.title = text;
    },
    toPriceName(event) {
      this.$router.push({
        name: "PriceNameList",
        params: { id: event.row.data.id },
      });
    },
    toPriceList(event) {
      this.$router.push({
        name: "PriceList",
        params: { id: event.row.data.id },
      });
    },
    async onInserting({ data }) {
      await api.insert(data);
      // await this.loadItems();
    },
    async onUpdating(data) {
      await api.update(data.oldData.id, data.newData);
    },
    async loadItems() {
      const { data } = await api.catalogPriceListGroups(this.$route.params.id);
      this.catalogPriceListGroups = data;
    },
    async loadCatalog() {
      const { data } = await apiCatalog.load(this.$route.params.id);
      this.catalog = data;
    },
    // onDragChange(e) {
    //   let visibleRows = e.component.getVisibleRows(),
    //       sourceNode = e.component.getNodeByKey(e.itemData.ID),
    //       targetNode = visibleRows[e.toIndex].node;
    //
    //   while (targetNode && targetNode.data) {
    //     if (targetNode.data.ID === sourceNode.data.ID) {
    //       e.cancel = true;
    //       break;
    //     }
    //     targetNode = targetNode.parent;
    //   }
    // },
    // onReorder(e) {
    //   const visibleRows = e.component.getVisibleRows();
    //
    //   if (e.dropInsideItem) {
    //     e.itemData.Head_ID = visibleRows[e.toIndex].key;
    //
    //     e.component.refresh();
    //   } else {
    //     const employees = this.employees.slice();
    //     const sourceData = e.itemData;
    //     const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
    //     let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;
    //
    //     if (targetData && e.component.isRowExpanded(targetData.ID)) {
    //       sourceData.Head_ID = targetData.ID;
    //       targetData = null;
    //     } else {
    //       sourceData.Head_ID = targetData ? targetData.Head_ID : -1;
    //     }
    //
    //     const sourceIndex = this.employees.indexOf(sourceData);
    //     employees.splice(sourceIndex, 1);
    //
    //     const targetIndex = this.employees.indexOf(targetData) + 1;
    //     employees.splice(targetIndex, 0, sourceData);
    //
    //     this.employees = employees;
    //   }
    // },
  },
};
</script>

<!--<style>-->
<!--#employees {-->
<!--  max-height: 600px;-->
<!--}-->
<!--</style>-->
