<template>
  <div class="container mt-3">
    <h1>Каталог: {{ catalogTitle }}</h1>
    <DxTreeList
      id="catalog-tree-grid"
      :data-source="elements"
      :expanded-row-keys="expandedRowKeys"
      :show-row-lines="true"
      :show-borders="true"
      :column-auto-width="true"
      items-expr="children"
      data-structure="tree"
    >
      <DxEditing
        :allow-updating="true"
        :allow-deleting="false"
        :allow-adding="false"
        mode="row"
      />

      <DxRowDragging
        :on-drag-change="onDragChange"
        :on-reorder="onReorder"
        :allow-drop-inside-item="allowDropInsideItem"
        :allow-reordering="allowReordering"
        :show-drag-icons="showDragIcons"
      />
      <DxColumn data-field="title" caption="Название" />
      <DxColumn type="buttons">
        <!--        <DxButton name="add" />-->
        <!--        <DxButton name="edit" />-->
        <!--        $router.push({name: 'ProductType', params: {id: }})-->
        <DxButton text="Редактировать" :on-click="toEditing" />
        <DxButton name="delete" />
      </DxColumn>
    </DxTreeList>
  </div>
</template>
<script>
import {
  DxColumn,
  DxRowDragging,
  DxTreeList,
  DxButton,
  DxEditing,
} from "devextreme-vue/tree-list";
import { employees } from "@/views/data";
import api from "@/modules/Catalog/api";
import _ from "lodash";

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxRowDragging,
    DxButton,
    DxEditing,
  },
  data() {
    return {
      employees,
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      elements: [],
    };
  },
  async mounted() {
    await this.loadTree();
    this.expandedRowKeys.push(this.catalog.id);
  },
  computed: {
    catalog() {
      return _.first(this.elements);
    },
    catalogTitle() {
      return this.catalog ? this.catalog.title : null;
    },
  },
  methods: {
    toEditing(event) {
      this.$router.push({
        name: "ElementEdit",
        params: { id: event.row.data.id },
      });
    },
    async onInserting({ data }) {
      await api.insert(data);
    },
    async create() {
      await api.create("test");
    },
    async loadTree() {
      const { data } = await api.catalogTree(this.$route.params.id);
      this.elements = [data];
    },
    onDragChange(e) {
      let visibleRows = e.component.getVisibleRows(),
        sourceNode = e.component.getNodeByKey(e.itemData.ID),
        targetNode = visibleRows[e.toIndex].node;

      while (targetNode && targetNode.data) {
        if (targetNode.data.ID === sourceNode.data.ID) {
          e.cancel = true;
          break;
        }
        targetNode = targetNode.parent;
      }
    },
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows();

      if (e.dropInsideItem) {
        e.itemData.Head_ID = visibleRows[e.toIndex].key;

        e.component.refresh();
      } else {
        const employees = this.employees.slice();
        const sourceData = e.itemData;
        const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
        let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

        if (targetData && e.component.isRowExpanded(targetData.ID)) {
          sourceData.Head_ID = targetData.ID;
          targetData = null;
        } else {
          sourceData.Head_ID = targetData ? targetData.Head_ID : -1;
        }

        const sourceIndex = this.employees.indexOf(sourceData);
        employees.splice(sourceIndex, 1);

        const targetIndex = this.employees.indexOf(targetData) + 1;
        employees.splice(targetIndex, 0, sourceData);

        this.employees = employees;
      }
    },
  },
};
</script>

<style>
#catalog-tree-grid {
  max-height: 600px;
}
</style>
