import axios from "axios";

export default {
  async getGuideList() {
    return await axios.get("/admin/guide");
  },
  async getGuideTypes() {
    return await axios.get("/admin/guide-types");
  },
  async getGuide(id) {
    return await axios.get(`/admin/guide/${id}`);
  },
  async getGuideItemList(id) {
    return await axios.get(`/admin/guide/${id}/item`);
  },
  async exportCsvByGuideId(id) {
    return await axios.get(`/admin/guide/${id}/export-csv/package-size`, {
      responseType: "blob", // Important
    });
  },
  async importGuidePackageSizeCsv(file) {
    const formData = new FormData();
    formData.append("import-csv", file);
    return await axios.post(`/admin/guide/import-csv/package-size`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async getGuideItem(id) {
    return await axios.get(`/admin/guide/item/${id}`);
  },
  async setGuideItemImage(itemId, formData) {
    return await axios.post(
      `/admin/guide/item/${itemId}/set-guide-item-image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  async insetGuideItem(guideId, data) {
    return await axios.post(`/admin/guide/${guideId}/item`, data);
  },
  async bordersUpdate(itemId, borders) {
    return await axios.post(`/admin/guide/${itemId}/borders`, borders);
  },
  // async updateGuideItem(guideId, data) {
  //     return await axios.post(`/admin/guide/${guideId}/item`, data)
  // },
};
