<template>
  <div class="container">
    <h1 class="mt-3">{{ translatedType }}: {{ element.title }}</h1>
    <div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="element.isVisible" id="element-is-active">
        <label class="form-check-label" for="element-is-active">
          Видимость
        </label>
      </div>
    </div>
    <!--    <pre>-->
    <!--      {{propertiesByFieldId}}-->
    <!--    </pre>-->
    <form action="#">
      <div class="row">
        <div class="col-lg-6 mt-3">
          <label for="i1" class="form-label">Название</label>
          <input
              type="text"
              id="i1"
              class="form-control"
              v-model="element.title"
          />
        </div>
        <div class="col-lg-6 mt-3">
          <label for="i2" class="form-label">Адрес</label>
          <input
              type="text"
              id="i2"
              class="form-control"
              v-model="element.slug"
          />
        </div>
        <div class="col-lg-6 mt-3">
          <label for="i3" class="form-label">Изображение</label>
          <input id="i3" class="form-control" type="file" @change="onImageUpload" ref="imageFile" accept="image/*" >
        </div>
        <div class="col-lg-6 mt-3" v-if="element.photo && element.photo.filePath">
          <img :src="staticHost + element.photo.filePath" alt="" class="img-fluid" style="max-height: 170px">
        </div>
<!--        <div class="col-lg-6 mt-3">-->
<!--&lt;!&ndash;          <label for="i3" class="form-label">Изображение</label>&ndash;&gt;-->
<!--&lt;!&ndash;          <input type="file" id="i3" class="form-control"/>&ndash;&gt;-->
<!--        </div>-->
        <div class="col-12 mt-3">
          <label for="i4" class="form-label">Контент</label>
          <textarea
              id="i4"
              class="form-control"
              v-model="element.content"
          ></textarea>
        </div>
      </div>
    </form>
    <CatalogProductElement :element="element" v-if="element.type === 'CatalogProductElement'" @reload-element-need="fetchElement"></CatalogProductElement>
  </div>
</template>
<script>
import api from "@/modules/Catalog/api";
import hierarchyApi from "@/modules/Hierarchy/api";
const staticHost = process.env.VUE_APP_STATIC_HOST;
import CatalogProductElement from "@/modules/Catalog/components/CatalogProductElement";
export default {
  components: {
    CatalogProductElement
  },
  data() {
    return {
      staticHost,
      element: {
        isVisible: false,
        title: null,
        photo: {
          filePath: null
        },
        product: {
          productType: {},
          properties: []
        }
      },
    };
  },
  computed: {
    type() {
      return this.element ? this.element.type : "";
    },
    translatedType() {
      if (this.type) {
        switch (this.type) {
          case "Catalog":
            return "Каталог";
          case "CatalogCategory":
            return "Категория";
          case "CatalogProduct":
            return "Товар";
        }
      }
      return "Элемент";
    },
    // propertyGroups() {
    //   if (
    //     this.element &&
    //     this.element.product &&
    //     this.element.product.properties
    //   ) {
    //     const group = _.groupBy(this.element.product.properties, (prop) => {
    //       return prop.fieldId;
    //     });
    //     console.log("group", group);
    //     return group;
    //   }
    //   return [];
    // },

  },
  async mounted() {
    await this.fetchElement();
  },
  watch: {
    'element.isVisible'(newVal) {
      if (this.element && this.element.id) {
        api.setIsVisible(this.element.id, newVal)
      }
    }
  },
  methods: {
    async fetchElement() {
      const {data} = await api.load(this.$route.params.id);
      this.element = data;
    },
    async onImageUpload() {
      if (this.$refs.imageFile.files[0]) {
        console.log(this.$refs.imageFile.files[0])
        let formData = new FormData();
        formData.append('file', this.$refs.imageFile.files[0]);
        await hierarchyApi.setElementPreview(this.$route.params.id, formData)
        await this.fetchElement();
      }
    }
  },
};
</script>
