import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import vSelect from "vue-select";
import 'axios-progress-bar/dist/nprogress.css'
import { loadProgressBar } from 'axios-progress-bar'


import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap/dist/css/bootstrap.css';
import "vue-select/dist/vue-select.css";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
// axios.defaults.withCredentials = true;


axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 500) {
        alert(`Ошибка \n${error.response.data.title} \n${error.response.data.detail}`);
    }
    return Promise.reject(error);
});

createApp(App)
    .component("v-select", vSelect)
    .use(VueAxios, axios)
    .use(store).use(router).mount("#app");
loadProgressBar();
