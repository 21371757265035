<template>
  <div>
    <AllowedWithValues
        :product-values="productValues"
        :allowed-with-ids="property.allowedWithIds"
        @add-allowed-with-value-ids="$emit('add-property-allowed-with-value-ids', {...$event, propertyId: property.id})"
        @remove-allowed-with-value-id="$emit('remove-property-allowed-with-value-id', {...$event, propertyId: property.id})"
    >
      <template v-slot:title>
        Доступно только для значений
      </template>
    </AllowedWithValues>
    <AllowedWithValues
        :product-values="productValues"
        :allowed-with-ids="property.allowedStrictWithIds"
        @add-allowed-with-value-ids="$emit('add-property-allowed-strict-with-ids', {...$event, propertyId: property.id})"
        @remove-allowed-with-value-id="$emit('remove-property-allowed-strict-with-id', {...$event, propertyId: property.id ? property.id : property})"
    >
      <template v-slot:title>
        Доступно <span class="fw-bold text-decoration-underline">строго</span> только для значений
      </template>
    </AllowedWithValues>
    <AllowedWithValues
        :product-values="propertiesIdName"
        :allowed-with-ids="property.excludeByPropertyIds"
        @add-allowed-with-value-ids="$emit('add-property-exclude', {propertyIds: $event.allowedWithValueIds, propertyId: property.id})"
        @remove-allowed-with-value-id="$emit('remove-property-exclude', {deselectPropertyId: $event.allowedWithValueId, propertyId: property.id ? property.id : property})"
    >
      <template v-slot:title>
        Отмена значения свойств при выборе
      </template>
    </AllowedWithValues>
  </div>
</template>
<script>
import AllowedWithValues from "@/modules/Catalog/components/Product/AllowedWithValues";
export default {
  components: {
    AllowedWithValues
  },
  props: {
    productValues: {
      type: Array,
    },
    allowedWithIds: {
      type: Array
    },
    property: {
      type: Object
    },
    propertiesIdName: {
      type: Array
    }
  }
}
</script>
