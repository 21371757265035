<template>
  <div>
    <div class="mb-2 d-flex flex-column">
      <div>
        {{ field.name }}{{ field.guide ? " - " + field.guide.name : "" }}
      </div>
      <div v-if="property" class="d-flex">
        <div class="me-2">
          <div class="form-check">
            <input
              :id="'isRequired' + property.id"
              class="form-check-input"
              type="checkbox"
              v-model="isRequired"
              @change="
                $emit('property-required-change', {
                  propertyId: property.id,
                  isRequired: isRequired,
                })
              "
            />
            <label class="form-check-label" :for="'isRequired' + property.id">
              Обязательное
            </label>
          </div>
        </div>
        <div class="me-2">
          [<a
            href="#"
            @click.prevent="isOpenPropertyOptions = !isOpenPropertyOptions"
            >Опции</a
          >]
        </div>
        <div class="me-2">
          [<a href="#" @click.prevent="$emit('property-remove', property)"
            >Удалить свойство</a
          >]
        </div>
      </div>
      <div v-else>
        [<a
          href="#"
          @click.prevent="$emit('property-create', { fieldId: field.id })"
          >Создать свойство</a
        >]
      </div>
    </div>
    <CompositeProperty
      v-if="
        propertiesByFieldId[field.id] &&
        propertiesByFieldId[field.id].type === 'CompositeProperty'
      "
      :field="field"
      :is-open-property-options="isOpenPropertyOptions"
      :price-names="priceNames"
      :product-values="productValues"
      :property="propertiesByFieldId[field.id]"
      :properties-id-name="propertiesIdName"
      @insert-value-price-modifier="
        $emit('insert-value-price-modifier', $event)
      "
      @add-allowed-with-value-ids="$emit('add-allowed-with-value-ids', $event)"
      @remove-allowed-with-value-id="
        $emit('remove-allowed-with-value-id', $event)
      "
      @remove-value="$emit('remove-value', $event)"
      @value-price-name-selected="$emit('value-price-name-selected', $event)"
      @value-price-name-deselected="
        $emit('value-price-name-deselected', $event)
      "
      @price-modifier-target-selected="
        $emit('price-modifier-target-selected', $event)
      "
      @price-modifier-price-name-selected="
        $emit('price-modifier-price-name-selected', $event)
      "
      @price-modifier-price-name-deselected="
        $emit('price-modifier-price-name-deselected', $event)
      "
      @price-modifier-delete="$emit('price-modifier-delete', $event)"
      @add-property-allowed-with-value-ids="
        $emit('add-property-allowed-with-value-ids', $event)
      "
      @remove-property-allowed-with-value-id="
        $emit('remove-property-allowed-with-value-id', $event)
      "
      @add-property-value="$emit('add-property-value', $event)"
      @add-property-allowed-strict-with-ids="
        $emit('add-property-allowed-strict-with-ids', $event)
      "
      @remove-property-allowed-strict-with-id="
        $emit('remove-property-allowed-strict-with-id', $event)
      "
      @value-set-preview="$emit('value-set-preview', $event)"
      @add-property-exclude="$emit('add-property-exclude', $event)"
      @remove-property-exclude="$emit('remove-property-exclude', $event)"
    >
    </CompositeProperty>
    <AttachmentProperty
      v-else-if="
        propertiesByFieldId[field.id] &&
        propertiesByFieldId[field.id].type === 'AttachmentProperty'
      "
      :field="field"
      :is-open-property-options="isOpenPropertyOptions"
      :price-names="priceNames"
      :product-values="productValues"
      :property="propertiesByFieldId[field.id]"
      @add-allowed-with-value-ids="$emit('add-allowed-with-value-ids', $event)"
      @add-property-allowed-with-value-ids="
        $emit('add-property-allowed-with-value-ids', $event)
      "
      @remove-property-allowed-with-value-id="
        $emit('remove-property-allowed-with-value-id', $event)
      "
      @allowed-number-attachments-change="
        $emit('allowed-number-attachments-change', $event)
      "
      @add-property-allowed-strict-with-ids="
        $emit('add-property-allowed-strict-with-ids', $event)
      "
      @remove-property-allowed-strict-with-id="
        $emit('remove-property-allowed-strict-with-id', $event)
      "
    >
    </AttachmentProperty>
    <FullNameDateProperty
      v-else-if="
        propertiesByFieldId[field.id] &&
        propertiesByFieldId[field.id].type === 'FullNameDateProperty'
      "
      :field="field"
      :is-open-property-options="isOpenPropertyOptions"
      :price-names="priceNames"
      :product-values="productValues"
      :property="propertiesByFieldId[field.id]"
      @add-allowed-with-value-ids="$emit('add-allowed-with-value-ids', $event)"
      @add-property-allowed-with-value-ids="
        $emit('add-property-allowed-with-value-ids', $event)
      "
      @remove-property-allowed-with-value-id="
        $emit('remove-property-allowed-with-value-id', $event)
      "
      @add-property-allowed-strict-with-ids="
        $emit('add-property-allowed-strict-with-ids', $event)
      "
      @remove-property-allowed-strict-with-id="
        $emit('remove-property-allowed-strict-with-id', $event)
      "
    >
    </FullNameDateProperty>
    <RetouchConfirmationProperty
      v-else-if="
        propertiesByFieldId[field.id] &&
        propertiesByFieldId[field.id].type === 'RetouchConfirmationProperty'
      "
      :field="field"
      :is-open-property-options="isOpenPropertyOptions"
      :price-names="priceNames"
      :product-values="productValues"
      :property="propertiesByFieldId[field.id]"
      @add-allowed-with-value-ids="$emit('add-allowed-with-value-ids', $event)"
      @add-property-allowed-with-value-ids="
        $emit('add-property-allowed-with-value-ids', $event)
      "
      @remove-property-allowed-with-value-id="
        $emit('remove-property-allowed-with-value-id', $event)
      "
      @add-property-allowed-strict-with-ids="
        $emit('add-property-allowed-strict-with-ids', $event)
      "
      @remove-property-allowed-strict-with-id="
        $emit('remove-property-allowed-strict-with-id', $event)
      "
      @retouch-confirm-true-price-name-selected="
        $emit('retouch-confirm-true-price-name-selected', $event)
      "
      @retouch-confirm-true-price-name-deselected="
        $emit('retouch-confirm-true-price-name-deselected', $event)
      "
    >
    </RetouchConfirmationProperty>
    <StandardPriceProperty
      v-else-if="
        propertiesByFieldId[field.id] &&
        propertiesByFieldId[field.id].type === 'StandardPriceProperty'
      "
      :field="field"
      :is-open-property-options="isOpenPropertyOptions"
      :price-names="priceNames"
      :product-values="productValues"
      :property="propertiesByFieldId[field.id]"
      @add-allowed-with-value-ids="$emit('add-allowed-with-value-ids', $event)"
      @add-property-allowed-with-value-ids="
        $emit('add-property-allowed-with-value-ids', $event)
      "
      @remove-property-allowed-with-value-id="
        $emit('remove-property-allowed-with-value-id', $event)
      "
      @add-property-allowed-strict-with-ids="
        $emit('add-property-allowed-strict-with-ids', $event)
      "
      @remove-property-allowed-strict-with-id="
        $emit('remove-property-allowed-strict-with-id', $event)
      "
      @standard-price-name-selected="
        $emit('standard-price-name-selected', $event)
      "
      @standard-price-name-deselected="
        $emit('standard-price-name-deselected', $event)
      "
    >
    </StandardPriceProperty>
  </div>
</template>
<script>
import CompositeProperty from "@/modules/Catalog/components/Product/Property/CompositeProperty";
import AttachmentProperty from "@/modules/Catalog/components/Product/Property/AttachmentProperty";
import FullNameDateProperty from "@/modules/Catalog/components/Product/Property/FullNameDateProperty";
import RetouchConfirmationProperty from "@/modules/Catalog/components/Product/Property/RetouchConfirmationProperty";
import StandardPriceProperty from "@/modules/Catalog/components/Product/Property/StandardPriceProperty";

export default {
  components: {
    CompositeProperty,
    AttachmentProperty,
    FullNameDateProperty,
    RetouchConfirmationProperty,
    StandardPriceProperty,
  },
  props: {
    field: {
      type: Object,
    },
    propertiesIdName: {
      type: Object,
    },
    property: {
      type: [Object, null],
    },
    propertiesByFieldId: {
      type: Array,
    },
    priceNames: {
      type: Array,
    },
    productValues: {
      type: Array,
    },
  },
  data() {
    return {
      isOpenPropertyOptions: false,
      isRequired: false,
    };
  },
  mounted() {
    if (this.property) {
      this.isRequired = this.property.isRequired;
    }
  },
  methods: {},
};
</script>
