<template>
  <router-view/>
<!--  <div class="container mt-3">-->
<!--    <h1>Точки выдачи</h1>-->
<!--    <div>-->
<!--      <form action="">-->

<!--      </form>-->
<!--    </div>-->
<!--    <table class="table table-bordered">-->
<!--      <thead>-->

<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="item in list" :key="item.id">-->
<!--        <td>-->
<!--          {{ item.id }}-->
<!--        </td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--    </table>-->
<!--  </div>-->
</template>
<script>

import api from "@/modules/Point/api";

export default {
  data() {
    return {
      list: [],
      cityList: [],
    }
  },
  async mounted() {
    await this.loadCityList();
  },
  methods: {
    async loadCityList() {
      const {data} = api.cityList();
      this.cityList = data;
    }
  }
}
</script>
