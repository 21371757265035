<template>
  <div>
    <PropertyOptions
        v-if="property && isOpenPropertyOptions"
        :product-values="productValues"
        :property="property"
        :properties-id-name="propertiesIdName"
        class="mb-3"
        @add-property-allowed-with-value-ids="$emit('add-property-allowed-with-value-ids', $event)"
        @remove-property-allowed-with-value-id="$emit('remove-property-allowed-with-value-id', $event)"
        @add-property-allowed-strict-with-ids="$emit('add-property-allowed-strict-with-ids', $event)"
        @remove-property-allowed-strict-with-id="$emit('remove-property-allowed-strict-with-id', $event)"
        @add-property-exclude="$emit('add-property-exclude', $event)"
        @remove-property-exclude="$emit('remove-property-exclude', $event)"
    ></PropertyOptions>
    <PropertyValue
        v-for="value in property.values"
        :key="value.id"
        :price-names="priceNames"
        :product-values="productValues"
        :value="value"
        class="border py-1 px-2"
        style="margin-top: -1px;"
        @insert-value-price-modifier="$emit('insert-value-price-modifier', $event)"
        @add-allowed-with-value-ids="$emit('add-allowed-with-value-ids', $event)"
        @remove-allowed-with-value-id="$emit('remove-allowed-with-value-id', $event)"
        @remove-value="$emit('remove-value', $event)"
        @value-price-name-selected="$emit('value-price-name-selected', $event)"
        @value-price-name-deselected="$emit('value-price-name-deselected', $event)"
        @price-modifier-target-selected="$emit('price-modifier-target-selected', $event)"
        @price-modifier-price-name-selected="$emit('price-modifier-price-name-selected', $event)"
        @price-modifier-price-name-deselected="$emit('price-modifier-price-name-deselected', $event)"
        @price-modifier-delete="$emit('price-modifier-delete', $event)"
        @value-set-preview="$emit('value-set-preview', $event)"
    >
    </PropertyValue>

    <div class="border py-1 px-2">
      <div class="row">
        <div class="col-5">
          <v-select
              v-model="currentGuideItemId"
              :options="guideItems"
              :reduce="item => item.id"
              class="style-chooser form-control-sm p-0"
              label="name"
              @open="getGuideItems"/>
        </div>
        <div class="col-5">
          <v-select
              v-model="currentPriceNameId"
              :options="priceNames"
              :reduce="priceName => priceName.id"
              class="style-chooser form-control-sm p-0"
              label="name"/>
        </div>
        <div class="col-2">
          <a href="#" @click.prevent="addPropertyValue">Добавить</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/modules/Guide/api";
import PropertyValue from "@/modules/Catalog/components/Product/PropertyValue";
import PropertyOptions from "@/modules/Catalog/components/Product/PropertyOptions";

export default {
  components: {
    PropertyOptions,
    PropertyValue
  },
  props: {
    field: {
      type: Object,
    },
    property: {
      type: Object,
    },
    propertiesIdName: {
      type: Array,
    },
    priceNames: {
      type: Array,
    },
    productValues: {
      type: Array,
    },
    isOpenPropertyOptions: {
      type: Boolean
    },
    // propertiesByFieldId: {
    //   type: Object
    // }
  },
  data() {
    return {
      currentGuideItemId: null,
      currentPriceNameId: null,
      guideItems: []
    }
  },
  methods: {
    async getGuideItems() {
      const {data} = await api.getGuideItemList(this.field.guide.id);
      this.guideItems = data.items
    },
    async addPropertyValue() {
      if (!this.currentGuideItemId) {
        alert('Требуется выбрать значени из справочника');
        return;
      }
      if (!this.property || !this.property.id) {
        alert('Свойства не существует');
        return;
      }
      this.$emit('add-property-value', {
        propertyId: this.property.id,
        itemId: this.currentGuideItemId,
        priceNameId: this.currentPriceNameId,
      });
      this.currentGuideItemId = null;
      this.currentPriceNameId = null;
    },
  }
}
</script>
