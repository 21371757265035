<template>
  <div class="container mt-3">
    <h1>
      {{
        this.$route.params.id !== "new"
          ? "Наименование " + item.name
          : "Добавить наименование"
      }}
    </h1>
    <component
      :is="itemType"
      :item="item"
      class="mt-3"
      @guide-image-file-select="onGuideItemImageSelect"
      @guide-item-persist="onGuideItemPersist"
      @borders-persist="onBorderPersist"
    ></component>
  </div>
</template>
<script>
import api from "@/modules/Guide/api";
import ImageItem from "@/modules/Guide/components/Item/ImageItem";
import BlankSizeItem from "@/modules/Guide/components/Item/BlankSizeItem";
import SizeItem from "@/modules/Guide/components/Item/SizeItem";
import ImageColorItem from "@/modules/Guide/components/Item/ImageColorItem";
import NamedItem from "@/modules/Guide/components/Item/NamedItem";

export default {
  components: {
    BlankSizeItem,
    ImageItem,
    SizeItem,
    ImageColorItem,
    NamedItem,
  },
  data() {
    return {
      guide: {
        guideItemType: null,
      },
      item: {},
    };
  },
  computed: {
    itemType() {
      if (this.$route.params.id !== "new") {
        return this.item.type;
      } else {
        return this.guide.guideItemType;
      }
    },
  },
  async mounted() {
    await this.getGuide();
    if (this.$route.params.id !== "new") {
      await this.getGuideItem();
    }
  },
  methods: {
    async getGuide() {
      const { data } = await api.getGuide(this.$route.params.guideId);
      this.guide = data;
    },
    async getGuideItem() {
      const { data } = await api.getGuideItem(this.$route.params.id);
      this.item = data;
    },
    async onGuideItemImageSelect(data) {
      let formData = new FormData();
      formData.append("file", data);
      await api.setGuideItemImage(this.$route.params.id, formData);
      await this.getGuideItem();
    },
    async onGuideItemPersist(item) {
      // if (this.$route.params.id !== 'new') {
      //   console.log('!')
      // } else {
      console.log(this.$route.params.guideId, item)
      const { data } = await api.insetGuideItem(
        this.$route.params.guideId,
        item
      );
      await this.$router.push({
        name: "GuideItemEdit",
        params: { guideId: this.$route.params.guideId, id: data.id },
      });
      await this.getGuideItem();
      // }
    },
    async onBorderPersist(borders) {
      console.log(this.$route.params.id, borders)
      const { data } = await api.bordersUpdate(
          this.$route.params.id,
          borders
      );
      console.log(data);
      // await this.getGuideItem();
    },
  },
};
</script>

<!--<style>-->
<!--#employees {-->
<!--  max-height: 600px;-->
<!--}-->
<!--</style>-->
