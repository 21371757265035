<template>
  <form action="#" @submit.prevent="$emit('guide-item-persist', currentItem)">
    <div class="row mb-3">
      <div class="col-12 col-lg-6">
        <label for="name1">Название</label>
        <input id="name1" v-model="currentItem.name" class="form-control" type="text">
      </div>
    </div>
  </form>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      default() {
        return {
          filePath: null
        }
      }
    }
  },
  data() {
    return {
      currentItem: {},
      imageFilePath: null,
    }
  },
  mounted() {
    this.currentItem = this.item;
  },
  watch: {
    item() {
      this.currentItem = this.item;
    }
  },
  methods: {
    async onImageUpload() {
      if (this.$refs.imageFile.files[0]) {
        this.$emit('guide-image-file-select', this.$refs.imageFile.files[0]);
      }
    }
  }
}
</script>
