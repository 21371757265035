<template>
    <div class="d-flex align-items-center row">
      <div class="col-md-8"></div>
      <div class="col-md-4">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <div class="btn-group" role="group">
            <button id="btnGroupDrop1" type="button" class="btn btn-outline-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Действия
            </button>
            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <li><a class="dropdown-item" href="#">Сохранить</a></li>
              <li><a class="dropdown-item" href="#">Удалить значения других справочников</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="$emit('on-field-deleting', field)">Удалить</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

export default {
  props: {
    field: {
      type: Object,
    },
  },
  data() {
    return {
    };
  },
  async mounted() {
  },
  methods: {
  },
};
</script>
