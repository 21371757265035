import axios from "axios";

const url = "/admin/catalog/product-type";
export default {
  async list() {
    return await axios.get(url);
  },
  async insert(data) {
    return await axios.post(url, data);
  },
  async load(id) {
    return await axios.get(`${url}/${id}`);
  },
  async fieldOrderChange(fieldIds) {
    return await axios.put(`${url}/field-order-change`, {
      fieldIds,
    });
  },
  async compositeFieldGuideChange(fieldId, guideId) {
    return await axios.put(`${url}/field/${fieldId}/set-guide`, {
      guideId,
    });
  },
  async setHelpMessage(fieldId, helpMessage) {
    return await axios.put(`${url}/field/${fieldId}`, {
      helpMessage,
    });
  },
  async fieldInsert(fieldGroupId, field) {
    return await axios.post(`${url}/field-group/${fieldGroupId}/field`, field);
  },
  async deleteField(id) {
    return await axios.delete(`${url}/field/${id}`);
  },
};
