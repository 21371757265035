export const employees = [
    {
        "id": 1,
        "slug": "catalog",
        "title": "Каталог 1",
        "elements": [
            {
                "id": 2,
                "catalogId": 1,
                "slug": "category_1",
                "title": "Портреты на памятник",
                "elements": [
                    {
                        "id": 5,
                        "catalogId": 1,
                        "slug": "product_1_1",
                        "title": "Продукт 1",
                        "elements": [],
                        "parentId": 2
                    },
                    {
                        "id": 6,
                        "catalogId": 1,
                        "slug": "product_1_2",
                        "title": "Продукт 2",
                        "elements": [],
                        "parentId": 2
                    },
                    {
                        "id": 7,
                        "catalogId": 1,
                        "slug": "product_1_3",
                        "title": "Продукт 3",
                        "elements": [],
                        "parentId": 2
                    },
                    {
                        "id": 8,
                        "catalogId": 1,
                        "slug": "product_1_4",
                        "title": "Продукт 4",
                        "elements": [],
                        "parentId": 2
                    },
                    {
                        "id": 9,
                        "catalogId": 1,
                        "slug": "product_1_5",
                        "title": "Продукт 5",
                        "elements": [],
                        "parentId": 2
                    }
                ],
                "parentId": 1
            },
            {
                "id": 3,
                "catalogId": 1,
                "slug": "category_2",
                "title": "Таблички",
                "elements": [
                    {
                        "id": 10,
                        "catalogId": 1,
                        "slug": "product_2_1",
                        "title": "Продукт 1",
                        "elements": [],
                        "parentId": 3
                    },
                    {
                        "id": 11,
                        "catalogId": 1,
                        "slug": "product_2_2",
                        "title": "Продукт 2",
                        "elements": [],
                        "parentId": 3
                    },
                    {
                        "id": 12,
                        "catalogId": 1,
                        "slug": "product_2_3",
                        "title": "Продукт 3",
                        "elements": [],
                        "parentId": 3
                    },
                    {
                        "id": 13,
                        "catalogId": 1,
                        "slug": "product_2_4",
                        "title": "Продукт 4",
                        "elements": [],
                        "parentId": 3
                    },
                    {
                        "id": 14,
                        "catalogId": 1,
                        "slug": "product_2_5",
                        "title": "Продукт 5",
                        "elements": [],
                        "parentId": 3
                    }
                ],
                "parentId": 1
            },
            {
                "id": 4,
                "catalogId": 1,
                "slug": "category_3",
                "title": "Футболки",
                "elements": [
                    {
                        "id": 15,
                        "catalogId": 1,
                        "slug": "product_3_1",
                        "title": "Продукт 1",
                        "elements": [],
                        "parentId": 4
                    },
                    {
                        "id": 16,
                        "catalogId": 1,
                        "slug": "product_3_2",
                        "title": "Продукт 2",
                        "elements": [],
                        "parentId": 4
                    },
                    {
                        "id": 17,
                        "catalogId": 1,
                        "slug": "product_3_3",
                        "title": "Продукт 3",
                        "elements": [],
                        "parentId": 4
                    },
                    {
                        "id": 18,
                        "catalogId": 1,
                        "slug": "product_3_4",
                        "title": "Продукт 4",
                        "elements": [],
                        "parentId": 4
                    },
                    {
                        "id": 19,
                        "catalogId": 1,
                        "slug": "product_3_5",
                        "title": "Продукт 5",
                        "elements": [],
                        "parentId": 4
                    }
                ],
                "parentId": 1
            }
        ],
        "parentId": null
    }
];
