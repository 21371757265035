<template>
  <div class="container mt-3">
    <h1>Справочник: {{ guide.name }}</h1>
    <div
      class="mt-3 mb-4 d-flex flex-lg-row flex-column align-items-lg-end"
      v-if="guide.type === 'BlankSizeGuide'"
    >
      <div>
        <label for="formFile">Импорт (посылки)</label>
        <input
          id="formFile"
          ref="importCsvFile"
          class="form-control"
          name="importCsvFile"
          placeholder="Импорт"
          type="file"
          @change="importCsv"
        />
      </div>
      <div class="ms-lg-3 mt-3 mt-lg-0">
        <label for="formFile">Экспорт (посылки)</label>
        <div>
          <button
            class="btn btn-outline-success text-nowrap"
            type="button"
            @click.prevent="exportCsv"
          >
            Скачать CSV (посылки)
          </button>
        </div>
      </div>
      <div class="ms-lg-3 mt-3 mt-lg-0">
        <!--        <p class="m-0 fw-bold small">-->
        <!--          Строки без идентификаторов создают новые точки, <br> с идентификаторами обновляют существующие точки.-->
        <!--        </p>-->
      </div>
    </div>
    <DxDataGrid
      v-if="isLoaded"
      :column-auto-width="true"
      :data-source="guide.items"
      :expanded-row-keys="expandedRowKeys"
      :show-borders="true"
      :show-row-lines="true"
      key-expr="id"
      parent-id-expr="parentId"
    >
      <DxEditing
        :allow-adding="false"
        :allow-deleting="false"
        :allow-updating="false"
        mode="row"
      />

      <DxRowDragging
        :allow-drop-inside-item="allowDropInsideItem"
        :allow-reordering="allowReordering"
        :show-drag-icons="showDragIcons"
      />
      <DxColumn caption="Название" data-field="name" />

<!--      <DxColumn-->
<!--        v-if="guide.type === 'BlankSizeGuide'"-->
<!--        data-field="packageSize.height"-->
<!--        caption="Ширина посылки"-->
<!--      />-->
<!--      <DxColumn-->
<!--        v-if="guide.type === 'BlankSizeGuide'"-->
<!--        data-field="packageSize.width"-->
<!--        caption="Высота посылки"-->
<!--      />-->
<!--      <DxColumn-->
<!--        v-if="guide.type === 'BlankSizeGuide'"-->
<!--        data-field="packageSize.depth"-->
<!--        caption="Глубина посылки"-->
<!--      />-->
      <DxColumn
        v-if="guide.type === 'BlankSizeGuide'"
        data-field="packageSize.weight"
        caption="Вес"
      />
<!--      <DxColumn-->
<!--        v-if="guide.type === 'BlankSizeGuide'"-->
<!--        data-field="packageSize.grossWeight"-->
<!--        caption="Вес Брутто посылки"-->
<!--      />-->
      <DxColumn
        v-if="guide.type === 'BlankSizeGuide'"
        data-field="packageSize.wareKey"
        caption="Артикул"
      />
      <DxColumn type="buttons">
        <!--        <DxButton name="add" />-->
        <!--        <DxButton name="edit"/>-->
        <!--        $router.push({name: 'GuideItemEdit', params: {id: }})-->
        <DxButton :on-click="toGuideItemEdit" text="Редактировать" />
        <!--        <DxButton name="delete"/>-->
      </DxColumn>
      <DxToolbar>
        <DxItem location="after" template="totalCountTemplate" />
      </DxToolbar>
      <template #totalCountTemplate>
        <div
          class="dx-item dx-toolbar-item dx-toolbar-button dx-toolbar-item-auto-hide dx-toolbar-text-auto-hide"
        >
          <div class="dx-item-content dx-toolbar-item-content">
            <div
              aria-label="Добавить строку"
              class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-button-has-icon dx-datagrid-toolbar-button dx-edit-button dx-datagrid-addrow-button"
              role="button"
              tabindex="0"
              title="Добавить строку"
              @click.prevent="addGuideItem"
            >
              <div class="dx-button-content">
                <i class="dx-icon dx-icon-edit-button-addrow"></i
                ><span class="dx-button-text">Добавить строку</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxItem,
  DxRowDragging,
  DxToolbar,
} from "devextreme-vue/data-grid";
import api from "@/modules/Guide/api";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxRowDragging,
    DxButton,
    DxEditing,
    DxToolbar,
    DxItem,
  },
  data() {
    return {
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      guide: {},
      isLoaded: false,
    };
  },
  async mounted() {
    await this.loadList();
  },
  methods: {
    // toProductTypeEditing(event) {
    //   this.$router.push({
    //     name: "ProductTypeEdit",
    //     params: { id: event.row.data.id },
    //   });
    // },
    async onInserting(/*{ data }*/) {
      // await api.insert(data);
      return false;
    },
    async create() {
      // await api.create("test");
      return false;
    },
    async loadList() {
      const { data } = await api.getGuideItemList(this.$route.params.id);
      this.guide = data;
      this.isLoaded = true;
    },
    async addGuideItem() {
      await this.$router.push({
        name: "GuideItemEdit",
        params: { guideId: this.$route.params.id, id: "new" },
      });
    },
    async toGuideItemEdit(data) {
      // console.log(data.row.key);
      // return
      await this.$router.push({
        name: "GuideItemEdit",
        params: { guideId: this.$route.params.id, id: data.row.key },
      });
    },
    async exportCsv() {
      const response = await api.exportCsvByGuideId(this.$route.params.id);
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "import_guide.csv";
      link.click();
    },
    async importCsv(event) {
      await api.importGuidePackageSizeCsv(event.target.files[0]);
      await this.loadList();
      this.$refs.importCsvFile.value = null;
    },
    // onToolbarPreparing: function (e) {
    //   var toolbarItems = e.toolbarOptions.items;
    //   // Modifies an existing item
    //   $.each(toolbarItems, function (_, item) {
    //     console.log(item.name);
    //     if (item.name === "revertButton") {
    //       // Change the item options here
    //       item.options.onClick = function (e) {
    //         alert('Custom Revert');
    //         $("#gridContainer").dxDataGrid("instance").cancelEditData();
    //       }
    //     }
    //   });
    // }
  },
};
</script>

<!--<style>-->
<!--#employees {-->
<!--  max-height: 600px;-->
<!--}-->
<!--</style>-->
