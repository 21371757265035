<template>
  <div>
    <div class="row mt-5 mb-3">
      <!--      {{productTypes}}-->
      <div class="col-12">
        <h5>Опции страницы</h5>
      </div>
      <div class="col-6">
        <form @submit.prevent="fillProductPropertiesFromSibling" class="">
          <div class="row">
            <div class="col-12">
              <label>Копирование свойств товара</label>
            </div>
            <div class="col-lg-8">
              <v-select
                v-model="currentFromProductSiblingId"
                :options="productSiblings"
                :reduce="(item) => item.id"
                class="style-chooser form-control-sm p-0"
                label="title"
              />
            </div>
            <div class="col-lg-4">
              <button type="submit" class="btn btn-sm btn-outline-info">
                Скопировать свойства
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-6">
        <form @submit.prevent="changeProductType" class="">
          <div class="row">
            <div class="col-12">
              <label>Смена типа товара</label>
            </div>
            <div class="col-lg-8">
              <v-select
                v-model="productTypeId"
                :options="productTypes"
                :reduce="(item) => item.id"
                class="style-chooser form-control-sm p-0"
                label="name"
              />
            </div>
            <div class="col-lg-4">
              <button type="submit" class="btn btn-sm btn-outline-info">
                Изменить тип
              </button>
            </div>
            <div class="col-lg-12">
              <div>{{element.product.productType.name}}</div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <form v-if="element.product" action="#">
      <div
        v-for="(fieldGroup, index) in element.product.productType.fieldGroups"
        :key="index"
        class="mb-5"
      >
        <div>
          <h5>{{ fieldGroup.name }}</h5>
        </div>
        <div class="row">
          <FieldProperty
            v-for="field in fieldGroup.fields"
            :key="field.id"
            :field="field"
            :price-names="priceNames"
            :product-values="fieldNameValues"
            :properties-by-field-id="propertiesByFieldId"
            :properties-id-name="propertiesIdName"
            :property="propertiesByFieldId[field.id]"
            class="col-12 mb-3"
            @insert-value-price-modifier="onInsertValuePriceModifier"
            @add-allowed-with-value-ids="addAllowedWithValues"
            @remove-allowed-with-value-id="removeAllowedWithValue"
            @remove-value="removeValue"
            @add-property-value="addPropertyValue"
            @value-price-name-selected="onValuePriceNameSelected"
            @value-price-name-deselected="onValuePriceNameRemove"
            @price-modifier-target-selected="onPriceModifierTargetSelected"
            @price-modifier-price-name-selected="
              onPriceModifierPriceNameSelected
            "
            @price-modifier-price-name-deselected="
              onPriceModifierPriceNameDeselected
            "
            @price-modifier-delete="onPriceModifierDelete"
            @property-remove="onPropertyRemove"
            @property-create="onPropertyCreate"
            @add-property-allowed-with-value-ids="addPropertyAllowedWithValues"
            @remove-property-allowed-with-value-id="
              removePropertyAllowedWithValue
            "
            @allowed-number-attachments-change="
              onPropertyAllowedNumberAttachmentsChange
            "
            @property-required-change="onPropertyRequiredChange"
            @add-property-allowed-strict-with-ids="
              addPropertyAllowedStrictWithValues
            "
            @remove-property-allowed-strict-with-id="
              removePropertyAllowedStrictWithValues
            "
            @retouch-confirm-true-price-name-selected="
              retouchConfirmTruePriceNameSelected
            "
            @retouch-confirm-true-price-name-deselected="
              retouchConfirmTruePriceNameDeSelected
            "
            @standard-price-name-selected="standardPriceSelected"
            @standard-price-name-deselected="standardPriceDeselected"
            @value-set-preview="valueSetPreview"
            @add-property-exclude="addPropertyExclude"
            @remove-property-exclude="removePropertyExclude"
          >
          </FieldProperty>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import _ from "lodash";
import FieldProperty from "@/modules/Catalog/components/Product/FieldProperty";
import api from "@/modules/Catalog/api";
import propertyValueApi from "@/modules/Catalog/propertyValueApi";
import productApi from "@/modules/Catalog/productApi";
import propertyApi from "@/modules/Catalog/propertyApi";
import productTypeApi from "@/modules/ProductTypes/api";

// const options = [{country: 'Canada', code: 'CA'},];

export default {
  components: {
    FieldProperty,
  },
  props: {
    element: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      priceNames: [],
      currentFromProductSiblingId: "",
      productSiblings: [],
      productTypes: [],
      productTypeId: null,
    };
  },
  computed: {
    propertiesIdName() {
      const data = _.cloneDeep(this.element.product.properties);
      const res = [];
      _.each(data, (prop) => {
        const name =
          prop.field && prop.field.guide && prop.field.guide.name
            ? `${prop.name} (${prop.field.guide.name})`
            : prop.name;
        res.push({ id: prop.id, name });
      });
      return res;
    },
    propertiesByFieldId() {
      const data = _.cloneDeep(this.element.product.properties);
      return _.mapKeys(data, (property) => {
        return property.field.id;
      });
    },
    fieldNameValues() {
      const data = _.cloneDeep(this.element.product.properties);
      const res = [];
      _.each(data, (property) => {
        _.each(_.values(property.values), (val) => {
          const name = `${val.item.name} - ${property.name} - ${property.field.guide.name}`;
          res.push({
            id: val.id,
            name,
          });
        });
      });
      return res;
    },
  },
  async mounted() {
    await this.loadProductTypes();
    await this.getCatalogElementPriceNames();
    await this.loadProductSiblings();
  },
  methods: {
    async getCatalogElementPriceNames() {
      const { data } = await api.getCatalogElementPriceNames(
        this.$route.params.id
      );
      this.priceNames = data;
    },
    async addPropertyValue({ propertyId, itemId, priceNameId }) {
      await propertyValueApi.addPropertyGuideItemValue(
        propertyId,
        itemId,
        priceNameId
      );
      await this.$emit("reload-element-need");
    },
    async removeValue(id) {
      await propertyValueApi.removeValue(id);
      await this.$emit("reload-element-need");
    },
    async retouchConfirmTruePriceNameSelected({ propertyId, priceNameId }) {
      await propertyValueApi.retouchConfirmTruePriceNameSelected(
        propertyId,
        priceNameId
      );
    },
    async retouchConfirmTruePriceNameDeSelected({ propertyId }) {
      await propertyValueApi.retouchConfirmTruePriceNameDeSelected(propertyId);
    },
    async standardPriceSelected({ propertyId, priceNameId }) {
      await propertyValueApi.standardPriceNameSelected(propertyId, priceNameId);
    },
    async standardPriceDeselected({ propertyId }) {
      await propertyValueApi.removeStandardPriceName(propertyId);
    },
    async onInsertValuePriceModifier({
      valueId,
      currentValuePriceModifierTargetValueId,
      currentValuePriceModifierPriceNameId,
    }) {
      await propertyValueApi.insertValuePriceModifier(
        valueId,
        currentValuePriceModifierTargetValueId,
        currentValuePriceModifierPriceNameId
      );
      await this.$emit("reload-element-need");
    },
    async addAllowedWithValues({ valueId, allowedWithValueIds }) {
      await propertyValueApi.addAllowedWithValues(valueId, allowedWithValueIds);
    },
    async removeAllowedWithValue({ valueId, allowedWithValueId }) {
      await propertyValueApi.removeAllowedWithValues(
        valueId,
        allowedWithValueId
      );
    },
    async addPropertyExclude({ propertyIds, propertyId }) {
      await console.log(propertyIds, propertyId);
      await propertyValueApi.addPropertyExcludeProperties(
        propertyId,
        propertyIds
      );
    },
    async removePropertyExclude({ deselectPropertyId, propertyId }) {
      await propertyValueApi.removePropertyExcludeProperty(
        propertyId,
        deselectPropertyId
      );
      await console.log(deselectPropertyId, propertyId);
    },
    async addPropertyAllowedStrictWithValues({
      propertyId,
      allowedWithValueIds,
    }) {
      await propertyValueApi.addPropertyAllowedStrictWithValues(
        propertyId,
        allowedWithValueIds
      );
    },
    async removePropertyAllowedStrictWithValues({
      propertyId,
      allowedWithValueId,
    }) {
      await propertyValueApi.removePropertyAllowedStrictWithValues(
        propertyId,
        allowedWithValueId
      );
    },
    async onValuePriceNameSelected({ valueId, priceNameId }) {
      await propertyValueApi.setValuePriceName(valueId, priceNameId);
    },
    async onValuePriceNameRemove({ valueId }) {
      await propertyValueApi.removeValuePriceName(valueId);
    },
    async onPriceModifierTargetSelected({
      valuePriceModifierId,
      targetValueId,
    }) {
      await propertyValueApi.setPriceModifierTargetValue(
        valuePriceModifierId,
        targetValueId
      );
    },
    async onPriceModifierPriceNameSelected({
      valuePriceModifierId,
      priceNameId,
    }) {
      await propertyValueApi.setPriceModifierPriceName(
        valuePriceModifierId,
        priceNameId
      );
    },
    async onPriceModifierPriceNameDeselected({ valuePriceModifierId }) {
      await propertyValueApi.removePriceModifierPriceName(valuePriceModifierId);
    },
    async onPriceModifierDelete({ valuePriceModifierId }) {
      await propertyValueApi.deletePriceModifier(valuePriceModifierId);
      await this.$emit("reload-element-need");
    },
    async onPropertyRemove(property) {
      if (!confirm("Подтвердите")) {
        return false;
      }
      await productApi.removeProperty(property.id);
      await this.$emit("reload-element-need");
    },
    async onPropertyCreate(data) {
      if (!confirm("Подтвердите")) {
        return false;
      }
      await productApi.createProperty(this.element.product.id, data);
      await this.$emit("reload-element-need");
    },
    async addPropertyAllowedWithValues({ propertyId, allowedWithValueIds }) {
      await propertyValueApi.addPropertyAllowedWithValues(
        propertyId,
        allowedWithValueIds
      );
    },
    async removePropertyAllowedWithValue({ propertyId, allowedWithValueId }) {
      await propertyValueApi.removePropertyAllowedWithValues(
        propertyId,
        allowedWithValueId
      );
    },
    async onPropertyAllowedNumberAttachmentsChange({
      propertyId,
      allowedNumberAttachments,
    }) {
      await propertyApi.setPropertyAllowedNumberAttachments(
        propertyId,
        allowedNumberAttachments
      );
    },
    async onPropertyRequiredChange({ propertyId, isRequired }) {
      await propertyApi.setPropertyIsRequired(propertyId, isRequired);
    },
    async valueSetPreview({ valueId, file }) {
      let formData = new FormData();
      formData.append("file", file);
      await propertyValueApi.setValuePreview(valueId, formData);
      await this.$emit("reload-element-need");
    },
    async loadProductSiblings() {
      const { data } = await productApi.getProductSiblings(this.element.id);
      this.productSiblings = data;
    },
    async loadProductTypes() {
      const { data } = await productTypeApi.list();
      this.productTypes = data;
    },
    async fillProductPropertiesFromSibling() {
      if (!this.currentFromProductSiblingId) {
        alert("Вы не выбрали товар");
        return false;
      }

      if (!confirm("Скопировать свойства товара?")) {
        return false;
      }

      await productApi.fillProductPropertiesFromSibling(
        this.element.product.id,
        this.currentFromProductSiblingId
      );
      await this.$emit("reload-element-need");
    },
    async changeProductType() {
      if (!this.productTypeId) {
        alert("Выберите тип товара");
        return;
      }
      await productApi.changeProductType(
        this.element.product.id,
        this.productTypeId
      );
      await this.$emit("reload-element-need");
    },
  },
};
</script>
