import axios from "axios";

const url = "/admin/catalog/property";

export default {
    async setPropertyAllowedNumberAttachments(
        propertyId,
        allowedNumberAttachments
    ) {
        return await axios.put(`${url}/${propertyId}/set-allowed-number-attachments`, {
            allowedNumberAttachments,
        });
    },
    async setPropertyIsRequired(
        propertyId,
        isRequired
    ) {
        return await axios.put(`${url}/${propertyId}/set-is-required`, {
            isRequired,
        });
    },
};
