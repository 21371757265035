<template>
  <div class="container mt-3">
    <h1>Упаковки</h1>
    <div class="mt-3 mb-4 d-flex flex-lg-row flex-column align-items-lg-end">
      <div>
        <label for="formFile">Импорт (упаковки)</label>
        <input
          id="formFile"
          ref="importCsvFile"
          class="form-control"
          name="importCsvFile"
          placeholder="Импорт"
          type="file"
          @change="importCsv"
        />
      </div>
      <div class="ms-lg-3 mt-3 mt-lg-0">
        <label for="formFile">Экспорт (упаковки)</label>
        <div>
          <button
            class="btn btn-outline-success text-nowrap"
            type="button"
            @click.prevent="exportCsv"
          >
            Скачать CSV (упаковки)
          </button>
        </div>
      </div>
    </div>

    <DxDataGrid
      :data-source="list"
      :show-row-lines="true"
      :show-borders="true"
      :expanded-row-keys="expandedRowKeys"
      :column-auto-width="true"
      key-expr="id"
      parent-id-expr="parentId"
    >
      <DxEditing
        :allow-updating="false"
        :allow-deleting="false"
        :allow-adding="false"
        mode="row"
      />

      <DxRowDragging
        :allow-drop-inside-item="allowDropInsideItem"
        :allow-reordering="allowReordering"
        :show-drag-icons="showDragIcons"
      />
      <DxColumn data-field="id" caption="Id" />
      <DxColumn data-field="width" caption="Ширина мм" />
      <DxColumn data-field="height" caption="Высота мм" />
      <DxColumn data-field="depth" caption="Глубина мм" />
      <DxColumn data-field="weight" caption="Вес гр" />

      <!--      <DxColumn type="buttons">-->
      <!--        <DxButton name="save"/>-->
      <!--        <DxButton name="cancel"/>-->
      <!--        <DxButton name="delete"/>-->
      <!--      </DxColumn>-->
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxColumn,
  DxRowDragging,
  DxDataGrid,
  // DxButton,
  DxEditing,
} from "devextreme-vue/data-grid";
import api from "@/modules/Package/api";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxRowDragging,
    DxEditing,
  },
  data() {
    return {
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      list: [],
    };
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    async getList() {
      const { data } = await api.list();
      this.list = data;
    },
    async exportCsv() {
      const response = await api.exportCsv();
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "import_package.csv";
      link.click();
    },
    async importCsv(event) {
      await api.importPackageCsv(event.target.files[0]);
      await this.getList();
      this.$refs.importCsvFile.value = null;
    },
  },
};
</script>
