import axios from "axios";

const url = "/admin/package";

export default {
  async list() {
    return await axios.get(url);
  },

  async exportCsv(id) {
    return await axios.get(`/admin/package/${id}/export-csv`, {
      responseType: "blob", // Important
    });
  },
  async importPackageCsv(file) {
    const formData = new FormData();
    formData.append("import-csv", file);
    return await axios.post(`/admin/package/import-csv`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
