<template>
  <div>
    <PropertyOptions
        v-if="property && isOpenPropertyOptions"
        :product-values="productValues"
        :property="property"
        class="mb-3"
        @add-property-allowed-with-value-ids="$emit('add-property-allowed-with-value-ids', $event)"
        @remove-property-allowed-with-value-id="$emit('remove-property-allowed-with-value-id', $event)"
    ></PropertyOptions>
    <div class="row">
      <div class="col-5">
        <label :for="`rctrue_${property.id}`">Наименование цены</label>
        <v-select class="style-chooser form-control-sm p-0"
                  v-model="currentPriceNameId"
                  :id="`rctrue_${property.id}`"
                  :options="priceNames" label="name"
                  :reduce="priceName => priceName.id"
                  @option:selected="$emit('retouch-confirm-true-price-name-selected', {propertyId: property.id, priceNameId: currentPriceNameId})"/>
      </div>
    </div>
  </div>
</template>

<script>
import PropertyOptions from "@/modules/Catalog/components/Product/PropertyOptions";

export default {
  components: {
    PropertyOptions,
  },
  props: {
    field: {
      type: Object,
    },
    property: {
      type: Object,
    },
    priceNames: {
      type: Array,
    },
    productValues: {
      type: Array,
    },
    isOpenPropertyOptions: {
      type: Boolean
    },
  },
  data() {
    return {
      currentPriceNameId: null,
      watchingValue: false,
    }
  },
  mounted() {
    this.currentPriceNameId = this.property.trueValPriceNameId
    this.$nextTick(() => {
      this.watchingValue = true;
    })
  },
  watch: {
    currentPriceNameId(newVal, oldVal) {
      if (!this.watchingValue) {
        return false;
      }
      if (!newVal && newVal !== oldVal) {
        this.$emit('retouch-confirm-true-price-name-deselected', {
          propertyId: this.property.id
        })
      }
    }
  },
  methods: {}
}
</script>
