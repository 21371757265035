import api from "@/modules/Guide/api";

export const guideModule = {
    namespaced: true,
    state: {
        guides: []
    },
    getters: {
        getGuides(state) {
            return state.guides;
        }
    },
    mutations: {
        setGuides(state, data) {
            state.guides = data;
        }
    },
    actions: {
        async loadGuides(context) {
            const {data} = await api.getGuideList()
            context.commit('setGuides', data);
        }
    }
}
