<template>
  <form action="#" @submit.prevent="$emit('guide-item-persist', currentItem)">
    <div class="row mb-3">
      <div class="col-12 col-lg-6">
        <label for="name1">Название</label>
        <input
          id="name1"
          v-model="currentItem.altName"
          class="form-control"
          type="text"
        />
      </div>
    </div>
    <div class="row mb-3" v-if="currentItem.id">
      <div class="col-12 col-lg-6">
        <label for="image">Изображение</label>
        <input
          id="image"
          class="form-control"
          type="file"
          @change="onImageUpload"
          ref="imageFile"
          accept="image/*"
        />
      </div>
      <div class="col-12 col-lg-6">
        <ImageItem
          v-if="currentItem.image"
          :image="currentItem.image"
        ></ImageItem>
      </div>
    </div>
    <div class="row mb-3">
      <div
        class="col-12 mb-3"
        v-for="(dimension, index) in dimensions"
        :key="index"
      >
        <div class="row" v-if="dimension">
          <div class="col-2">
            <label for="">Ширина заготовки см</label>
            <input
              type="number"
              class="form-control"
              v-model="dimension.blankWidth"
              @input="onDChange"
            />
          </div>
          <div class="col-2">
            <label for="">Высота заготовки см</label>
            <input
              type="number"
              class="form-control"
              v-model="dimension.blankHeight"
              @input="onDChange"
            />
          </div>
          <div class="col-2">
            <label for="">Вес рамки гр</label>
            <input
              type="number"
              class="form-control"
              v-model="dimension.weight"
              @input="onDChange"
            />
          </div>
          <div class="col-2">
            <button
              class="mt-4 btn btn-outline-info"
              type="button"
              @click.prevent="removeDimension(index)"
            >
              -
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-2">
            <label for="">Ширина заготовки см</label>
            <input
              type="number"
              class="form-control"
              v-model="blankDimension.blankWidth"
            />
          </div>
          <div class="col-2">
            <label for="">Высота заготовки см</label>
            <input
              type="number"
              class="form-control"
              v-model="blankDimension.blankHeight"
            />
          </div>
          <div class="col-2">
            <label for="">Вес рамки гр</label>
            <input
              type="number"
              class="form-control"
              v-model="blankDimension.weight"
            />
          </div>
          <div class="col-2">
            <button
              class="mt-4 btn btn-outline-info"
              type="button"
              @click.prevent="addDimension"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button type="submit" class="btn btn-outline-primary">
          {{ currentItem.id ? "Сохранить" : "Добавить" }}
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import ImageItem from "@/modules/Guide/components/ItemImage";
import _ from "lodash";

const masterBlankDimension = {
  blankWidth: null,
  blankHeight: null,
  weight: null,
};
export default {
  components: {
    ImageItem,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          filePath: null,
        };
      },
    },
  },
  data() {
    return {
      currentItem: {},
      imageFilePath: null,
      dimensions: [],
      blankDimension: {},
    };
  },
  mounted() {
    this.currentItem = this.item;
    this.blankDimension = _.clone(masterBlankDimension);
    this.dimensions = _.clone(this.item.border);
  },
  watch: {
    item() {
      this.currentItem = this.item;
    },
  },
  methods: {
    async onImageUpload() {
      if (this.$refs.imageFile.files[0]) {
        this.$emit("guide-image-file-select", this.$refs.imageFile.files[0]);
      }
    },
    async removeDimension(index) {
      this.dimensions.splice(index, 1);
      await this.updateDimension();
    },
    async addDimension() {
      if (this.dimensions) {
        this.dimensions = _.concat(this.dimensions, this.blankDimension);
      } else {
        this.dimensions = _.concat([], this.blankDimension);
      }
      await this.updateDimension();
      this.blankDimension = _.clone(masterBlankDimension);
    },
    onDChange: _.debounce(async function () {
      await this.updateDimension();
    }, 500),
    async updateDimension() {
      await this.$emit("borders-persist", {
        borders: _.filter(this.dimensions, (el) => !_.isNull(el)),
      });
    },
  },
};
</script>
