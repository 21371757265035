import axios from "axios";

const url = "/admin/catalog/product/property";

export default {
  async removeProperty(id) {
    return await axios.delete(`${url}/${id}`);
  },
  async getProductSiblings(elementId) {
    return await axios.get(
      `/admin/hierarchy/element/${elementId}/get-siblings-product-elements`
    );
  },
  async fillProductPropertiesFromSibling(currentProductId, fromProductId) {
    return await axios.put(
      `/admin/catalog/product/${currentProductId}/fill-properties`,
      { fromProductId }
    );
  },
  async changeProductType(currentProductId, productTypeId) {
    return await axios.put(
      `/admin/catalog/product/${currentProductId}/change-product-type`,
      { productTypeId }
    );
  },
  async createProperty(productId, data) {
    return await axios.post(
      `/admin/catalog/product/${productId}/property`,
      data
    );
  },
};
