<template>
  <div class="container mt-3">
    <h1>Типы товаров</h1>
    <DxTreeList
        id="employees"
        :column-auto-width="true"
        :data-source="elements"
        :expanded-row-keys="expandedRowKeys"
        :show-borders="true"
        :show-row-lines="true"
        key-expr="id"
        parent-id-expr="parentId"
        @row-inserting="onInserting"
    >
      <DxEditing
          :allow-adding="true"
          :allow-deleting="false"
          :allow-updating="true"
          mode="row"
      />

      <DxRowDragging
          :allow-drop-inside-item="allowDropInsideItem"
          :allow-reordering="allowReordering"
          :on-drag-change="onDragChange"
          :on-reorder="onReorder"
          :show-drag-icons="showDragIcons"
      />
      <DxColumn caption="Название" data-field="name"/>
      <DxColumn type="buttons">
        <DxButton
            :on-click="dupType"
            text="Дублировать"
        />
        <DxButton name="delete"/>
        <DxButton
            :on-click="toProductTypeEditing"
            text="Редактировать"
        />
        <DxButton name="add"/>
      </DxColumn>
    </DxTreeList>
  </div>
</template>
<script>
import {
  DxColumn,
  DxRowDragging,
  DxTreeList,
  DxButton,
  DxEditing,
} from "devextreme-vue/tree-list";
import {employees} from "@/views/data";
import api from "@/modules/ProductTypes/api";

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxRowDragging,
    DxButton,
    DxEditing,
  },
  data() {
    return {
      employees,
      allowDropInsideItem: false,
      allowReordering: false,
      showDragIcons: false,
      expandedRowKeys: [],
      elements: [],
    };
  },
  async mounted() {
    await this.loadTree();
  },
  methods: {
    toProductTypeEditing(event) {
      this.$router.push({
        name: "ProductTypeEdit",
        params: {id: event.row.data.id},
      });
    },
    async dupType(e) {
      // console.log(e.row.data.name)
      // console.log(e.row.data.name)
      const name = prompt("Введите новое название", e.row.data.name)
      if (!name) {
        return false;
      }
      await this.axios.post(
        `/admin/catalog/product-type/${e.row.data.id}/duplicate`,
        { name }
      );
      console.log(name)
      await this.loadTree();
    },
    async onInserting({data}) {
      await api.insert(data);
    },
    async create() {
      await api.create("test");
    },
    async loadTree() {
      const {data} = await this.axios.get("/admin/catalog/product-type");
      this.elements = data;
    },
    onDragChange(e) {
      let visibleRows = e.component.getVisibleRows(),
          sourceNode = e.component.getNodeByKey(e.itemData.ID),
          targetNode = visibleRows[e.toIndex].node;

      while (targetNode && targetNode.data) {
        if (targetNode.data.ID === sourceNode.data.ID) {
          e.cancel = true;
          break;
        }
        targetNode = targetNode.parent;
      }
    },
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows();

      if (e.dropInsideItem) {
        e.itemData.Head_ID = visibleRows[e.toIndex].key;

        e.component.refresh();
      } else {
        const employees = this.employees.slice();
        const sourceData = e.itemData;
        const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
        let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

        if (targetData && e.component.isRowExpanded(targetData.ID)) {
          sourceData.Head_ID = targetData.ID;
          targetData = null;
        } else {
          sourceData.Head_ID = targetData ? targetData.Head_ID : -1;
        }

        const sourceIndex = this.employees.indexOf(sourceData);
        employees.splice(sourceIndex, 1);

        const targetIndex = this.employees.indexOf(targetData) + 1;
        employees.splice(targetIndex, 0, sourceData);

        this.employees = employees;
      }
    },
  },
};
</script>

<style>
#employees {
  max-height: 600px;
}
</style>
